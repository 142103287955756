.dashboard-wrapper {
    font-family: 'Graphik';
    font-style: normal;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media(max-width: 769px) {
        height: 100vh;
        overflow: auto;
        margin-top: 0;
        margin-bottom: 0;
    }

    .special-letter-image {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ECE9F1;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        width: 1.7rem;
        height: 1.7rem;
        font-size: 0.7rem;
        font-weight: 600;
        background-color: #14B0BF;
    }

    .chart-word-off {
        z-index: 10;
        width: 100%;
    }

    .chart-board {
        height: 27.8vh;
        padding: 3%;

        @media(max-width: 1400px) {
            // margin-top: 40px;
            height: 28.2vh;
            padding: 0.5em;
        }
    }

    .low-rated-review-wrapping {
        margin: auto;

        @media(max-width: 769px) {
            margin-top: 1%;
        }
    }

    .feedback-review {
        width: 50%;
        margin: 0 0 1% auto;

        @media(max-width: 769px) {
            margin-bottom: 3% auto;
            width: 100%;
        }
    }

    .review-section {
        .review-section-container {
            padding: 0 2.2%;
            // padding: 1% 2.1% 1% 2.1%;
            width: 100%;
            display: flex;

            justify-content: center;
            gap: 1%;

            @media(max-width: 769px) {
                flex-direction: column;
                // width: fit-content;
                gap: 0;
                padding: 0;
            }
        }

        .review-testimonial-container {
            height: 90.4vh;
            width: 60%;

            @media(max-width: 769px) {
                height: 100%;
                width: 100%;
                border-radius: 0;
                padding: 20px 15px 0 15px;
            }

            background: #F5F8FF 0% 0% no-repeat padding-box;
            padding: 20px 15px 10px 15px;
            // padding: 20px 25px;
            border-radius: 25px;
        }

        // padding: 10px 0;
        .review-container {
            display: flex;
            // flex-wrap: wrap;
            justify-content: center;
            gap: 1.2%;

            @media(max-width: 769px) {
                flex-direction: column;
                gap: 1rem;
                margin-bottom: 1rem;
            }
        }

        .review-table-section {
            width: 38%;
            background: #F5F8FF 0% 0% no-repeat padding-box;
            border-radius: 25px;
            padding: 20px 15px 10px 15px;
            // margin-right: 1.5rem;

            .latest-last-review {
                // margin-top: 1rem;
                margin-bottom: 1rem;
            }

            @media(max-width: 769px) {
                border-radius: 0;
                width: 100%;
                padding: 0 15px 10px 15px;
                margin-right: 0 !important;
            }

            display: flex;
            flex-direction: column;
            justify-content: center;

            .review-table {
                // height: 220px;
                // @media (max-width: 769px) {
                //     height: 200px;
                // }
                height: 100%;
                overflow-y: auto !important;
            }

            table {
                display: flex;
                flex-direction: column;
                padding: 0 10px;
                height: 100%;

                tbody {
                    height: 100%;
                }

                tr {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-bottom: 1px solid #ECE9F1;
                    padding: 8px 0;
                    align-items: center;
                    height: 20%;
                }

                thead {
                    tr {
                        th {
                            font-family: 'Graphik';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 24px;
                            color: #545454;
                            flex: 1;
                            text-align: left;
                        }
                    }
                }

                tbody {
                    tr {
                        // padding: 2.3% 0;

                        // @media(max-width: 1400px) {
                        //     padding: 2% 0;

                        //     @media(max-height: 768px) {
                        //         padding: 1.4% 0;
                        //     }
                        // }

                        td {
                            font-family: 'Graphik';
                            font-style: normal;
                            color: #000000;
                            // flex: 1;
                            vertical-align: middle;

                            // text-align: left;
                            .all-source-avatar {
                                height: auto;
                                width: 2vw;

                                @media(max-width: 769px) {
                                    // width: 6.8vw;
                                    width: 5vw;
                                }
                            }

                            .social-media {
                                height: auto;
                                width: 1.2vw;

                                @media(max-width: 769px) {
                                    width: 5vw;
                                    // width: 6.8vw;
                                }

                            }

                            .user-name {
                                font-weight: 600;
                                font-size: 0.9em;

                                // @media(max-width: 1400px) {
                                //     font-size: 1em;
                                // }

                                // line-height: 13px;
                                color: #000000;
                            }

                            .source-des {
                                font-weight: 500;
                                font-size: 0.765rem;

                                // line-height: 10px;
                                color: #5A5A5A;

                                @media(max-width: 769px) {
                                    display: none !important;
                                }
                            }

                            .source-des-mobile {
                                display: none;

                                @media(max-width: 769px) {
                                    font-weight: 400;
                                    // line-height: 10px;
                                    color: #5A5A5A;
                                    font-size: 0.7rem;
                                    display: block !important;
                                }
                            }

                            .rating {
                                font-weight: 500;
                                font-size: 1em;
                                // line-height: 13px;
                                color: #5A5A5A;

                            }

                            // .social-media{
                            //     height: 18px;
                            //     width: 18px;
                            // }
                        }
                    }
                }
            }

        }
    }

    .testimonial-section {
        padding: 0;
        // margin-top: 1%;
        display: flex;
        gap: 1.2%;

        .review-container {
            width: 50%;

            @media(max-width: 769px) {
                width: 100%;
            }
        }

        @media(max-width: 769px) {
            flex-direction: column;
        }

        .pagination {
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            padding: 3px 5px;

            &:hover {
                background: #FFFFFF 0% 0% no-repeat padding-box;
            }
        }
    }
}


.canvasjs-chart-credit {
    display: none !important;
}