.selection-container {
    min-height: auto;
    max-height: 540px;
    width: 100%;
    overflow: hidden;
    padding: 20px 0;

    .selection-header {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 14px;
        color: #000000;
        padding-bottom: 10px;
    }

    .selection-footer {
        width: 100%;
        display: flex;
        gap: 10px;
        justify-content: center;
        padding-top: 15px;

        button {
            width: 100px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 16px;
            opacity: 1;
            background: #007AFF;
            font-size: 14px;
            font-weight: normal;
            line-height: 0;
            letter-spacing: 0px;
            color: #FFFFFF;
            transition: all 0.5s ease-in-out;

            &:hover {
                color: #eeeeee;
            }
        }
    }

    .selection-body {
        min-height: auto;
        max-height: 445px;
        overflow: auto;
        padding: 0 7px 0 10px;
        margin: 0 13px 0 10px;

        .template-container {
            border-radius: 10px;
            border: 1px solid #007AFF;
            overflow: hidden;
            margin: 8px;

            .template-image {
                height: 120px;
                width: 100%;
            }

            .selection-place {
                display: flex;
                justify-content: space-between;
                padding: 8px 5px;

                img {
                    height: auto;
                    width: auto;
                    cursor: pointer;
                }
            }
        }

        &::-webkit-scrollbar-thumb {
            background: #A3A3A3 !important;
            // height: 10px !important;
            border-radius: 10px;
            cursor: pointer;
            min-height: 30px;
            /* Set your desired fixed height */
            max-height: 30px;
            /* Ensure it doesn't exceed the desired fixed height */
            background-clip: padding-box;
            /* Adjusts the background to stay within the thumb bounds */
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #fff;
            background-color: #fff !important;
            margin: 0 !important;
            // background-color: transparent ;
            // border-radius: 10px;
        }
    }

}