.user-table {
    width: 90%;
    margin: 0 auto;

    .ant-table-header .ant-table-thead tr th {
        background: transparent !important;
        color: #17173A !important;
        font-weight: 600;
    }


    .ant-table-header .ant-table-thead {
        background: #fff;
        color: #17173A;
    }

    .ant-table-row {
        // display: block;
        width: 100% !important;
        margin: 0 0 !important;
        color: #17173A !important;

        .ant-table-cell {
            padding: 1% 2% !important;
            border-top: 1px solid var(--border-inbox, #D3E0FF);
            border-bottom: 1px solid var(--border-inbox, #D3E0FF);
        }
    }

    .ant-table-thead {
        // display: block;
        width: 100% !important;
        margin: 0 0 !important;

        .ant-table-cell {
            padding: 1.5% 2% !important;

            &::before {
                content: none !important;
            }
        }
    }


    .ant-table table {
        .ant-table-body {
            height: 50vh;
            overflow-y: auto;
        }

        font-size: 0.9rem;

        @media(max-width: 1400px) {
            font-size: 0.75rem;

            @media(max-width: 769px) {
                font-size: 0.9rem;
            }
        }

        margin: 0;

        .ant-table-measure-row {
            height: 0 !important;
            overflow: hidden !important;

            .ant-table-tbody>tr>td {
                padding: 0 0 0 0 !important;
            }
        }
    }


    .user-table .ant-table table .ant-table-measure-row td {
        padding: 0 0 0 0 !important;
    }



    .rowClassName1 td {
        border-left: none !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .rowClassName1 td {
        border-right: none !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    th {
        // padding: 0 0 5px 0;
        border: none;
        vertical-align: middle;

        div {
            margin: 0;
            width: 100%;
            // border-right: 2px solid #00000011;

            .check-btn {
                // margin-top: 2px;
                cursor: pointer;
            }
        }

        .last-header-title {
            border-right: none !important;
        }
    }

    // .table-header-title {
    //     font-size: 15px;
    //     font-weight: 600;
    //     color: #000;
    //     // margin-bottom: 10px;
    // }

    // .table-body-title {
    //     font-size: 14px;
    //     font-weight: 400;
    //     color: #000;
    //     // margin-bottom: 10px;
    // }

    td {
        // padding: 0 0 5px 0;
        vertical-align: middle;

        div {
            margin: 0 !important;
            width: 100%;
            // border-right: 1px solid #fff;

            .check-btn {
                margin-top: 0 !important;
                cursor: pointer;
            }
        }

        .last-body-title {
            border-right: none !important;
        }
    }

}