.custom-link-gap {
    gap: 5% !important;
}

.table>:not(caption)>*>* {
    border-bottom-width: 0px !important;
}

// .logo-url-container {
//     display: flex;
//     flex-direction: row;
//     gap: 5%;
//     width: 65%;
//     justify-items: center;
// }

// .edit-delete-container {
//     display: flex;
//     flex-direction: row;
//     gap: 30%;
//     width: 15%;
//     justify-content: flex-end;
//     justify-items: center;

//     img {
//         cursor: pointer;
//     }
// }

.add-custom-button {
    position: absolute;
    top: 11%;
    right: 5%;
    width: -moz-fit-content;
    width: fit-content;

    button {
        width: 10vw;
        height: 5vh;
        background: #D8E3FF;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }

    @media(max-width: 1025px) {
        top: 10%;

        button {
            width: 13vw;
            height: 5vh;
            background: #D8E3FF;
            border-radius: 5px;
            display: block;

        }

        @media(max-width: 769px) {
            position: relative;
            top: 0;
            right: 3%;
            z-index: 5;
            margin: 0 0 0 auto;

            button {
                width: 130px;
                height: 5vh;
                background: #D8E3FF;
                border-radius: 5px;
                display: block;

            }
        }
    }
}

.dropdown-custom-link-types {
    width: 12vw;
    margin-top: 2%;
    border-radius: 5px;
    background: var(--bg-white, #FFF);
    box-shadow: 0.6000000238418579px 0.6000000238418579px 5px 2px rgba(23, 23, 58, 0.15);
    padding: 10% 0;

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            width: 100%;
            padding: 2% 10%;
            text-align: justify;
            color: #101828;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            font-weight: normal;
            font-size: 0.9rem;

            @media(max-width: 1400px) {
                font-size: 0.75rem;

                @media(max-width: 769px) {
                    font-size: 0.9rem;
                }
            }

            &:hover {
                background: var(--bg-inbox-selection, #F3F4F5);
            }
        }
    }
}

// scroll bar
.form-component {
    width: 100%;
    overflow: auto;

    .scroll-hover {
        overflow-y: auto;
        // height: 50vh;
        height: 100%;
        // background: #FFF;
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-right: 0.5%;
    }

    .table-hover {
        // overflow-y: auto;
        // height: 50vh;
        height: 100%;
        // background: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 0;
        padding-right: 0.5%;
    }

    thead,
    tbody {
        width: 100%;

        tr {
            width: 100%;
            display: flex;

            th,
            td {
                width: 100%;
                color: #17173A;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }
            }

            th {
                font-weight: 500;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 1.8% 1.5%;
            }
        }
    }

    transition: all 0.9s ease-in-out;

    &:hover {
        ::-webkit-scrollbar-thumb {
            // -webkit-transition: background-color 0.5s ease-in-out !important;
            // -moz-transition: background-color 0.5s ease-in-out !important;
            // -o-transition: background-color 0.5s ease-in-out !important;
            // transition: background-color 0.5s ease-in-out !important;
            background: #ddd !important;
            height: 10px !important;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        cursor: pointer;
    }

    ::-webkit-scrollbar-track {
        height: 5px !important;
        box-shadow: inset 0 0 5px #fff;
        background-color: #fff !important;
        margin: 0 !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: transparent !important;
        border-radius: 10px !important;
    }
}

.form-button-container {
    margin-top: 4%;
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5%;

    button {
        width: fit-content;
        padding: 0 5%;
        height: 5vh;
        background: #D8E3FF;
        border: none;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #17173A;
        font-size: 0.9rem;
        font-weight: 400;

        @media (max-width: 1400px) {
            font-size: 0.75rem;

            @media (max-width: 769px) {
                font-size: 0.9rem;
            }
        }
    }
}

.form-link-container {
    width: 100%;
    overflow: hidden;

    .form-textarea {
        margin-top: 3.5%;
        width: 100%;
        resize: none;
        padding: 1.5%;
        border-radius: 3px;
        border: 1px solid var(--icon-general, #ABB9CC);

        font-size: 0.9rem;
        font-weight: 400;

        @media(max-width: 1400px) {
            font-size: 0.75rem;

            @media(min-width: 768px) {
                font-size: 0.9rem;
            }
        }

        &::placeholder {
            color: var(--text-placeholder, #98A2B3);
            font-size: 0.9rem;

            @media(max-width: 1400px) {
                font-size: 0.75rem;

                @media(min-width: 768px) {
                    font-size: 0.9rem;
                }
            }
        }
    }

    .date-time-container {
        display: flex;
        flex-direction: row;
        gap: 5%;
        margin-top: 3.5%;
        width: 100%;
        text-align: center;

        font-size: 0.9rem;
        font-weight: 400;

        @media(max-width: 1400px) {
            font-size: 0.75rem;

            @media(min-width: 768px) {
                font-size: 0.9rem;
            }
        }

        .from-date-time,
        .to-date-time {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .date-time {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 3%;

                .date-picker {
                    width: 65%;

                    &::placeholder {
                        color: var(--text-placeholder, #98A2B3);
                        font-size: 0.9rem;

                        @media(max-width: 1400px) {
                            font-size: 0.75rem;

                            @media(min-width: 768px) {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }

            }
        }
    }

    .back-container {
        width: 100%;
        padding: 2% 3% 2% 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        // gap: 5%;
        // margin-bottom: 2%;

        .search-input {
            width: 80%;
            margin: 0 auto;
            height: 8.5%;
            // padding-left: 2%;
            border-radius: 3px;
            border: 1px solid var(--border-big-button, #A4BCFD);
            background: var(--bg-white, #FFF);
            color: var(--text-placeholder, #98A2B3);

            .search-icon {
                margin-right: 5px;
            }

            input {
                font-size: 0.9rem;
                font-weight: 400;

                @media(max-width: 1400px) {
                    font-size: 0.75rem;

                    @media(max-width: 769px) {
                        // width: 100% !important;
                        font-size: 0.9rem;
                    }
                }
            }

        }

        img {
            cursor: pointer;
        }
    }

    .form {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: 5%;
        border-radius: 0px 5px 5px 0px;
        border-left: 5px solid var(--button-right-nav-bar, #1570EF);
        background: var(--bg-inbox-selection, #EEF4FF);
        padding: 3.5% 3% 6% 3%;

        .input-group {

            width: 100%;
            position: relative;

            .ant-row {
                width: 100%;
                height: 100%;
            }

            margin: 0;

            input {
                width: 100%;
                height: 100%;
                padding: 2% 0;
                border: none;
                border-bottom: 0.5px solid var(--icon-focus, #344054);
                background: transparent;
                outline: none;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }
            }

            .image-label,
            .input-label {
                position: absolute;
                top: 20%;
                right: 1%;
                cursor: pointer;
                color: #17173A;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    .add-video-button,
    .add-image-button {
        margin-top: 3.5%;
        width: 25%;
        // height: 5vh;
        padding: 1.2% 0;
        border-radius: 3px;
        border: 0.5px solid var(--icon-general, #ABB9CC);
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5%;
        color: #17173A;
        font-size: 0.9rem;
        font-weight: 400;
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        span {
            margin-right: 2%;
        }

        &:hover {
            background: var(--bg-inbox-selection, #D8E3FF);
        }

        @media (max-width: 1400px) {
            font-size: 0.75rem;

            @media (max-width: 769px) {
                width: 100%;
                font-size: 0.9rem;
            }
        }
    }

    .child-list-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
        width: 100%;
        background: var(--bg-inbox-selection, #EEF4FF);

        .child-item {
            // width: 100%;
            border-radius: 0px 0px 3px 3px;
            // border-bottom: 1px solid var(--border-inbox, #D3E0FF);
            color: #17173A;

            .logo-text {
                display: flex;
                gap: 5%;
            }
        }
    }

    .child-list-container {
        margin-top: 3.5%;
        width: 100%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 0px 5px 5px 0px;
        background: var(--bg-inbox-selection, #EEF4FF);
        padding: 1% 0 0 0;

        .child-item {
            width: 100%;
            padding: 1% 3%;
            border-radius: 0px 0px 3px 3px;
            border-bottom: 1px solid var(--border-inbox, #D3E0FF);
            color: #17173A;

            .input-container {
                height: 100%;
                padding: 1.5% 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 15%;

                .logo-text-label-container {
                    display: flex;
                    // border: 1px solid blue;
                    flex-direction: row;
                    gap: 5%;
                    width: 65%;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 0.9rem;
                    font-weight: 400;

                    @media (max-width: 1400px) {
                        font-size: 0.75rem;

                        @media (max-width: 769px) {
                            font-size: 0.9rem;
                        }
                    }

                    .logo-text {
                        width: 65%;
                        display: flex;
                        gap: 5%;
                        align-items: center;

                        img {
                            width: 2vw;
                            // border-radius: 50%;
                            height: 3vh;
                            object-fit: contain;
                        }
                    }
                }

                .edit-delete-container {
                    display: flex;
                    flex-direction: row;
                    // border: 2px solid green;
                    gap: 30%;
                    // width: 25%;
                    // justify-content: space-between;
                    justify-content: flex-end;
                    align-items: center;
                    justify-items: center;

                    img {
                        cursor: pointer;
                    }
                }
            }


            .image-label {
                // height: 50px;
                outline: none;
            }

        }

    }

}