.blinking-dot,
.blinking-dot-animation {
    background-color: #fa703f;
    // height: 11px;
    // width: 11px;
    padding: 8px;
    border-radius: 50%;
}

.blinking-dot-animation {
    animation: blink 1s infinite alternate;
}

@keyframes blink {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.6;
    }
}