.conversation-container-mobile {
    display: none;
    height: 100vh;
    background-color: #F4F3FF;

    @media(max-width: 769px) {
        display: block;
    }

    font-family: 'Graphik';
    font-style: normal;

    .conversation-header {
        height: 20%;

        .header-top {
            background-color: #ffffff;
            height: 40%;

            @media(min-height: 812px) {
                height: 45%;
            }

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;

            .provider {
                width: 18px;
                height: 18px;
            }

            .back {
                width: 17px;
                height: 17px;
                border-radius: 50%;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .header-body {
            height: 45%;

            @media(min-height: 812px) {
                height: 35%;
            }

            border-top: 2px solid #D3E0FF;
            border-bottom: 2px solid #D3E0FF;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 10px;

            .body-left,
            .bot-body-left {
                height: 100%;
                width: 82%;
                // overflow-x: auto;
                // border-right: 1px solid red;
                position: relative;

                .first-ws {
                    display: flex;
                    flex-direction: row;
                    // gap: 18px;
                    gap: 5%;

                    img {
                        width: 6vw;
                        height: auto;
                    }

                    .user-avatar,
                    .image-logo {
                        height: 32px;
                        width: auto;
                        border-radius: 50%;
                    }


                    .user-name-avatar {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 28px;
                        width: 32px;
                        border-radius: 50%;
                        font-size: 14px;
                        font-weight: 500;
                        color: #FFFFFF;
                        background: #14B0BF;
                    }

                    .user-info {

                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        padding-top: 5px;

                        .user-name {
                            font-weight: 600;
                        }

                        .review-type {
                            font-weight: 400;
                            font-style: italic;
                            font-size: 0.75rem;
                            // line-height: 13px;

                            @media(max-width: 376px) {
                                font-size: 0.65rem;
                                line-height: 0.65rem;
                                // line-height: 9px;
                            }

                            letter-spacing: 0.01em;
                            color: #17173A;
                        }
                    }
                }

                .second-ws {
                    position: absolute;
                    // bottom: 0;
                    bottom: 10%;
                    left: 13px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;

                    img {
                        width: 6vw;
                        height: auto;
                    }
                }

                .image-logo {
                    width: 27px;
                    height: 27px;
                    border-radius: 50%;
                }

                .user-name,
                .ws-name {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 13px;
                    letter-spacing: 0.03em;

                    @media(max-width: 376px) {
                        font-size: 12px;
                        line-height: 10px;
                        letter-spacing: 0.01em;
                    }

                    color: #17173A;
                }
            }

            .bot-body-left {
                width: 60%;
            }

            .conversation-features-media {
                display: flex;
                flex-direction: row;
                align-items: center;

                .count-down-container,
                .whats-app-close-container {
                    border: 1px solid #ABB9CC;
                    border-radius: 5px;
                    height: 5vh;
                    width: fit-content;
                    padding: 0 10%;
                    display: flex;
                    align-items: center;
                    justify-self: center;
                    gap: 0.5rem;
                }

                .whats-app-close-container {
                    padding: 0 5%;
                    font-size: 0.75rem;
                    cursor: pointer;
                }

                .countdown {
                    color: #667085;
                    font-size: 0.875rem;
                    font-weight: 500;
                }

                img {
                    // width: 2vw;
                    // height: 3vh;
                    width: 22px;
                    height: 22px;
                }

                .features,
                .whats-app-features {
                    margin-right: 30px;

                    img {
                        margin-right: 0.6rem;
                        cursor: pointer;
                        width: 19px;
                        height: 19px;
                    }
                }

                .whats-app-features {
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                    margin-right: 55px;
                }

                // .media {
                //     img {
                //         // height: 3vh;
                //     }
                // }
            }

            .body-right {
                height: 100%;
                width: 18%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 0.6875rem;

                img {
                    width: 3.5vw;
                    height: 3vh;
                    border-radius: 50%;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        .header-bottom {
            // height: 20%;

            // @media(max-height: 812px) {
            //     height: 15%;
            // }

            // border: 1px solid blue;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 25%;
            padding: 0 5%;

            span {
                font-weight: 600;
                font-size: 0.6875rem;
                line-height: 1.2rem;
                letter-spacing: 0.03em;
                color: #17173A;
                transition: all 0.5s ease-in-out;

                &:hover {
                    color: #1570EF;
                }
            }

        }
    }

    .conversation-body {
        // height: 100%;
        // height: 66%;
        display: flex;
        flex-direction: column-reverse;
        // border-bottom: 1px solid #e2e905;
        // overflow-y: scroll;
        padding: 2% 0 1% 1%;
        scrollbar-width: none;
        overflow: hidden;

        ::-webkit-scrollbar {
            background-color: transparent !important;
            width: 0 !important;
            -ms-overflow-style: none;
            display: none !important;
        }

        .conversation-person-1,
        .conversation-person-2 {
            margin: 0 0 3% 0;
            // margin: 0 0 10% 0;

            .date {
                font-weight: 400;
                font-size: 0.6875rem;
                line-height: 1.8rem;
                letter-spacing: 0.01em;
                color: #667085;
                padding: 0 11%;

            }

            .details {
                display: flex;
                align-items: flex-end;
                // flex-direction: row-reverse;
                text-align: left;

                .user-avatar {
                    // height: 1.8rem;
                    // width: 2rem;
                    height: 32px;
                    // width: auto;
                    width: auto;
                    border-radius: 50%;
                    // padding: 1%;
                }

                .user-name-avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 28px;
                    width: 32px;
                    border-radius: 50%;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFFFF;
                    background: #14B0BF;
                }

                .description {
                    display: block;
                    margin: 0 2%;
                    padding: 3% 4%;
                    width: 100%;

                    background: #FFFFFF;
                    border-radius: 10px;
                    font-weight: 500;
                    font-size: 0.6875rem;
                    letter-spacing: 0.01em;

                    color: #17173A;

                    .user_name {
                        font-weight: 600;
                    }

                    .user-ratings-container {
                        margin-bottom: 0.5rem;
                    }

                    .rating-social-email {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 3%;
                        margin: 0 0;

                        color: #667085;
                        width: 50%;

                        .star {
                            height: 0.8rem;
                            width: 0.8rem;
                            margin: 0 0.3rem 0 0;
                        }

                        .rating {
                            font-weight: 500;
                            font-size: 0.75rem;
                        }
                    }

                    p {
                        white-space: pre-line;
                        font-weight: 400;
                        font-size: 0.75rem;
                        line-height: 1.25rem;
                        color: #101828;
                        text-align: justify;
                    }

                    .like-edit-reply {
                        display: flex;
                        align-items: flex-end;
                        gap: 2%;
                        margin-top: 0.5rem;

                        .image-likes {
                            min-width: 25%;
                            display: flex;
                            align-items: flex-end;

                            gap: 8%;

                            .image-like {
                                width: 4vw;
                                height: 2vh;
                                cursor: pointer;
                            }


                            .likes {
                                margin-left: 1%;
                                font-weight: 400;
                                font-size: 0.65rem;
                                line-height: 1.25rem;
                                letter-spacing: 0.01em;

                                color: #667085;
                            }
                        }

                        .reply-edit {
                            font-weight: 400;
                            font-size: 0.65rem;
                            line-height: 1.25rem;
                            letter-spacing: 0.01em;

                            color: #667085;
                        }
                    }

                    .client,
                    .client-container {
                        font-weight: 400;
                        font-size: 0.65rem;
                        line-height: 1.2rem;
                        letter-spacing: 0.01em;

                        color: #17173A;
                    }
                }
            }
        }

        .conversation-person-2 {
            // margin-top: 15px;

            .details {
                flex-direction: row-reverse;
            }
        }
    }

    .conversation-footer,
    .bot-conversation-footer {
        position: absolute;
        padding: 0.1% 0;
        background: #F4F3FF;
        width: 100%;
        bottom: 1%;


        @media(max-height: 900px) {
            bottom: 2%;

            @media(max-height: 800px) {
                bottom: 2%;

                @media(max-height: 768px) {
                    bottom: 3%;
                }
            }
        }

        // @media(max-height: 1075px){
        //     bottom: 8%;
        // }
        // @media (max-height: 769px) {
        //     bottom: 8%;
        // }
        font-family: 'Graphik';
        font-style: normal;

        .sender {
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1.2rem;
            letter-spacing: 0.03em;
            color: #667085;
        }

        .message-box {
            padding: 0 2.5%;

            .message {
                position: relative;
                height: 12vh;
                background: #D4DBE7;
                border-radius: 10px;
                overflow: hidden;

                .text-container {
                    height: 100%;
                    background-color: #FFFFFF;
                    padding: 3px 10px;

                    textarea,
                    .debounce-textarea {
                        display: flex;
                        justify-content: left;
                        height: 100%;
                        width: 70%;
                        border: none;
                        line-height: 18px;
                        color: #667085;
                        font-size: 12px;
                        outline: none;
                        padding: 15px;

                        &::placeholder {
                            font-size: 10px;
                            font-weight: lighter;
                            letter-spacing: 0.09em;
                            color: #bcbec2;
                        }
                    }
                }

                .send-reply-action {
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: absolute;

                    top: 10%;
                    right: 5%;

                    img {
                        height: 5vh;
                        width: 5vw;
                        cursor: pointer;
                        margin-top: 1.2rem;
                    }
                }

                .click-reply-des {
                    position: absolute;
                    height: 100%;
                    width: 100%;

                    top: 0;
                    padding: 0 15px;
                    left: 0;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                    color: #98A2B3;
                    background: #D4DBE7;
                }

                .spot-vanish {
                    // background: red;
                    background: #D4DBE7;
                    height: 15px;
                    width: 20px;
                    position: absolute;
                    bottom: -15%;
                    right: 30%;
                    border-radius: 5px;
                }
            }
        }
    }

    .bot-conversation-footer {
        @media(max-height: 900px) {
            bottom: 0;

            @media(max-height: 800px) {
                bottom: 0;

                @media(max-height: 768px) {
                    bottom: 0;
                }
            }
        }
    }

    .conversation-details-mobile-container {
        height: 80%;
    }
}

.post {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 0 4% 0;

    p {
        cursor: pointer;
        font-weight: 400;
        font-size: 0.65rem;
        line-height: 1.25rem;
        letter-spacing: 0.01em;

        color: #667085;
    }

    .post-container {
        width: 75%;
        text-align: justify;

        @media(max-width: 769px) {
            width: 100%;
        }

        min-height: 80px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 0.8rem 0.6rem;

        .post-header {
            display: flex;
            // align-items: flex-start;
            gap: 0.5rem;
            // border: 2px solid #E2E905;

            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // gap: 0.01rem;

                color: #17173A;

                .user-name {
                    font-weight: 600;
                    font-size: 0.7rem !important;
                    line-height: 0.75rem;
                    letter-spacing: 0.01em;
                }

                p {
                    font-weight: 400;
                    font-size: 0.55rem;
                    line-height: 1.2rem;
                    color: #667085;
                }
            }
        }

        .post-body {
            margin-top: 0.5rem;

            p {
                white-space: pre-line;
                font-weight: 500;
                font-size: 0.7rem;
                line-height: 1rem;
                letter-spacing: 0.01em;
                color: #17173A;
            }
        }

        .post-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.5rem;
            // border: 2px solid #E2E905;
            font-weight: 500;
            font-size: 0.58rem;
            color: #667085;

            .post-footer-left {
                display: flex;
                align-items: center;
                gap: 1.5rem;

                .like-edit-reply {
                    display: flex;
                    align-items: flex-end;
                    gap: 2%;
                    margin-top: 0.5rem;
                    width: 100%;


                    .image-likes {
                        min-width: 30%;
                        display: flex;
                        align-items: center;

                        gap: 5%;

                        .image-like {
                            width: 1vw;
                            height: 2vh;
                            cursor: pointer;
                        }


                        .likes {
                            margin-left: 1%;
                            font-weight: 400;
                            font-size: 0.65rem;
                            line-height: 1.25rem;
                            letter-spacing: 0.01em;

                            color: #667085;
                        }
                    }

                    .reply-edit {
                        font-weight: 400;
                        font-size: 0.65rem;
                        line-height: 1.25rem;
                        letter-spacing: 0.01em;

                        color: #667085;
                        margin-left: 3%;
                        cursor: pointer;
                    }
                }
            }

            .post-footer-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 2px;
            }
        }
    }
}

.conversation-section-container-lg {
    @media(max-width: 769px) {
        display: none;
    }

    font-family: 'Graphik';
    font-style: normal;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: #17173A;
    position: relative;

    .conversation-section-video-feedback,
    .conversation-section {
        height: 81% !important;

        @media(max-height: 1080px) {
            height: 81% !important;

            @media(max-height: 980px) {
                height: 79% !important;

                @media(max-height: 902px) {
                    height: 77% !important;

                    @media(max-height: 801px) {
                        height: 70% !important;
                        // @media(max-height: 770px){
                        //     height: 68% !important;
                        // }
                    }

                }
            }
        }

        padding: 2px;

        .conversation-body,
        .ai-bot-conversation-body {

            height: 100% !important;
            display: flex;
            flex-direction: column-reverse;
            // border-bottom: 1px solid #e2e905;
            // overflow-y: scroll;
            padding: 2.3% 0 5% 2.3%;
            // padding: 14px 0 5px 14px;
            scrollbar-width: none;

            ::-webkit-scrollbar {
                background-color: transparent !important;
                width: 0 !important;
                -ms-overflow-style: none;
                display: none !important;
            }

            .conversation-person-1,
            .conversation-person-2 {
                margin: 0 0 3% 0;

                .date {
                    font-family: 'Graphik';
                    font-weight: 400;
                    // font-size: 0.65rem;
                    // line-height: 1.25rem;
                    font-size: 10px;
                    line-height: 10px;
                    letter-spacing: 0.01em;
                    color: #667085;
                    padding: 0 6.5%;

                }

                .details {
                    display: flex;
                    align-items: flex-end;
                    // flex-direction: row-reverse;
                    text-align: left;

                    .user-avatar {
                        // height: 1.8rem;
                        // width: 2rem;
                        height: 32px;
                        // width: auto;
                        width: auto;
                        border-radius: 50%;
                        // padding: 1%;
                    }

                    .user-name-avatar {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 28px;
                        width: 32px;
                        border-radius: 50%;
                        font-size: 14px;
                        font-weight: 500;
                        color: #FFFFFF;
                        background: #14B0BF;
                    }

                    .description {
                        display: block;
                        margin: 0 1.2%;
                        // padding: 2.5% 3% 2.5% 3%;
                        padding: 3%;
                        width: 75%;
                        background: #FFFFFF;
                        border-radius: 10px;
                        font-weight: 400;
                        font-size: 12px;
                        // font-size: 0.75rem;
                        // line-height: 0.75rem;
                        // letter-spacing: 0.01em;
                        color: #17173A;

                        // .recommend-not-recommend-container {
                        //     // padding-bottom: 0.4rem;
                        // }


                        .recommend-not-recommend-image {
                            // margin-top: 1%;
                            width: 0.9vw;
                            height: 1.9vh;
                        }

                        .user-ratings-container {
                            margin-bottom: 0.5rem;
                        }

                        .user_name {
                            font-weight: 600;
                        }

                        .rating-social-email {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 3%;
                            margin: 0 0;

                            color: #667085;
                            width: 50%;

                            .star {
                                height: 0.8rem;
                                width: 0.8rem;
                                margin: 0 0.3rem 0 0;
                            }

                            .rating {
                                // font-size: 0.75rem;
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }

                        p {
                            white-space: pre-line;
                            margin: 0.4rem 0 0.5rem 0;
                            // font-weight: 500;
                            // font-size: 0.75rem;
                            // line-height: 1.25rem;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: #101828;
                            text-align: justify;
                            overflow: auto;
                        }

                        .like-edit-reply {
                            display: flex;
                            align-items: flex-end;
                            gap: 0%;
                            // margin-top: 0.5rem;

                            .image-likes {
                                min-width: 15%;
                                display: flex;
                                align-items: flex-end;

                                gap: 5%;

                                .image-like {
                                    width: 1vw;
                                    height: 2vh;
                                    cursor: pointer;
                                }


                                .likes {
                                    // margin-left: 1%;
                                    font-weight: 500;
                                    font-size: 0.65rem;
                                    line-height: 0.65rem;
                                    letter-spacing: 0.01em;

                                    color: #667085;
                                }
                            }

                            .reply-edit {
                                cursor: pointer;
                                font-weight: 500;
                                font-size: 0.6875rem;
                                line-height: 0.6875rem;
                                letter-spacing: 0.01rem;

                                color: #667085;
                                margin-left: 3%;
                                cursor: pointer;
                            }
                        }

                        .client,
                        .client-container {
                            font-weight: 400;
                            font-size: 0.75rem;
                            line-height: 1.25rem;

                            color: #17173A;
                        }



                    }
                }
            }

            .conversation-person-2 {
                // margin-top: 20px !important;

                .details {
                    flex-direction: row-reverse;
                }
            }
        }

        .ai-bot-conversation-body {
            height: auto !important;
            // display: block !important;
        }
    }

    .conversation-section-video-feedback {
        height: 100% !important;
    }

    .conversation-header {
        border-bottom: 1.5px solid #D3E0FF;
        // height: 61px;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 15px 15px;
        text-transform: capitalize;

        .person-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;

            .person-1 {
                display: flex;
                align-items: center;

                img,
                .user-avatar {
                    // height: 1.8rem;
                    // width: 2rem;
                    height: 32px;
                    // width: auto;
                    width: auto;
                    border-radius: 50%;
                    // padding: 1%;
                }

                .user-name-avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 28px;
                    width: 32px;
                    border-radius: 50%;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFFFF;
                    background: #14B0BF;
                }

                .description {
                    // font-size: 0.75rem;
                    // font-weight: 600;
                    font-size: 12px;
                    font-weight: 600;
                    // margin-left: 25px;
                    margin-left: 22px;

                    .review {
                        // font-size: 0.6875rem;
                        font-size: 11px;
                        font-weight: 400;
                        font-style: italic;
                    }
                }
            }

            .person-2 {
                display: flex;
                align-items: center;
                font-weight: 500;
                position: absolute;
                top: 20px;
                left: 15px;
                // gap: 8%;
                gap: 6px;
                width: 100%;

                img,
                .img-avatar,
                .image-logo,
                .user-avatar {
                    // height: 1.8rem;
                    // width: 2rem;
                    // width: auto;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    // padding: 1%;
                }

                .user-name-avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 28px;
                    width: 32px;
                    border-radius: 50%;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFFFF;
                    background: #14B0BF;
                }

                .user-2 {
                    // font-size: 0.75rem;
                    font-size: 12px;
                    font-weight: 600;
                    display: block;
                    // width: 320px;
                }
            }
        }

        .conversation-features-media {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0.875rem;

            .count-down-container,
            .whats-app-close-container {
                border: 1px solid #ABB9CC;
                border-radius: 5px;
                height: 5vh;
                width: fit-content;
                padding: 0 10%;
                display: flex;
                align-items: center;
                justify-self: center;
                gap: 0.5rem;
            }

            .whats-app-close-container {
                padding: 0 5%;
                font-size: 0.75rem;
                cursor: pointer;
            }

            .countdown {
                color: #667085;
                font-size: 0.875rem;
                font-weight: 500;
            }

            img {
                // width: 2vw;
                // height: 3vh;
                width: 22px;
                height: 22px;
            }

            .features,
            .whats-app-features {
                margin-right: 30px;

                img {
                    margin-right: 0.6rem;
                    cursor: pointer;
                    width: 19px;
                    height: 19px;
                }
            }

            .whats-app-features {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                margin-right: 55px;
            }

            // .media {
            //     img {
            //         // height: 3vh;
            //     }
            // }
        }
    }



    .conversation-footer {
        // position: sticky;
        position: absolute;
        padding: 0.1% 0;
        width: 100%;
        // bottom: 54px;
        bottom: 30px;

        // @media(max-height: 900px) {
        //     bottom: 2%;

        //     @media(max-height: 800px) {
        //         bottom: 2%;

        //         @media(max-height: 768px) {
        //             // bottom: 6%;
        //             bottom: 3%;
        //         }
        //     }
        // }

        font-family: 'Graphik';
        font-style: normal;
        background: #F4F3FF;

        .sender {
            // font-size: 0.75rem;
            // line-height: 1.2rem;
            // font-weight: 500;
            // letter-spacing: 0.03em;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.12px;
            color: #667085;
        }

        .message-box {
            padding: 0 2.5%;

            .message {
                position: relative;
                height: 12vh;
                background: #D4DBE7;
                border-radius: 10px;
                overflow: hidden;

                .text-container {
                    height: 100%;
                    background-color: #FFFFFF;
                    padding: 3px 10px;

                    textarea,
                    .debounce-textarea {
                        display: flex;
                        justify-content: left;
                        height: 100%;
                        width: 70%;
                        border: none;
                        line-height: 18px;
                        color: #667085;
                        font-size: 12px;
                        outline: none;
                        padding: 15px;

                        &::placeholder {
                            font-size: 10px;
                            font-weight: lighter;
                            letter-spacing: 0.09em;
                            color: #bcbec2;
                        }
                    }
                }

                .reply-btn {
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: absolute;

                    height: 4vh;
                    right: 4%;
                    top: 35%;
                    border-radius: 5px;

                    button {
                        height: 100%;
                        width: 6vw;
                        border: none;
                        font-weight: 500;
                        font-size: 0.6875rem;
                        line-height: 0.6875rem;
                        letter-spacing: 0.03em;
                        color: #17173A;
                        border-radius: 5px;
                    }

                    img {
                        height: 50%;
                        margin-left: 10%;
                        cursor: pointer;
                    }
                }

                .click-reply-des {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    padding: 0 3%;
                    left: 0;
                    font-weight: 400;
                    // font-size: 0.75rem;
                    // line-height: 0.75rem;
                    // letter-spacing: 0.01em;
                    font-size: 11.33px;
                    line-height: 11.33px;
                    letter-spacing: 0.113px;
                    display: flex;
                    align-items: center;
                    color: #98A2B3;
                    background: #D4DBE7;
                }

                // .spot-vanish {
                //     // background: red;
                //     background: #D4DBE7;
                //     height: 15px;
                //     width: 20px;
                //     position: absolute;
                //     bottom: -15%;
                //     right: 30%;
                //     border-radius: 5px;
                // }
            }
        }
    }
}

// ==== Bot CSS start ======
.whatsapp-header {
    // style={{ display: 'flex', alignItems: 'center', gap: 10 }}
    background: #EEF4FF;
    padding: 8px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-part {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            margin: 0 !important;
            padding: 0;
        }

        .whatsapp-header-title {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .file-type-size {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

    }

    .right-part {
        .whatsapp-download {
            border-radius: 500%;
            cursor: pointer;
        }
    }
}

.whats-app-details-header {
    padding: 5% 3%;

    p {
        padding: 0 1%;
        width: fit-content;
        text-align: start;
        font-weight: 500;
        font-size: 0.75rem;
        // line-height: 0.75rem;
        letter-spacing: 0.03em;
        color: #000000;
        border-bottom: 2px solid #175CD3;
    }
}

.whats-app-inbox-wall {
    border-top: 1px solid #D3E0FF;
}

.bot-inbox-wall,
.whats-app-inbox-wall {
    height: 18%;

    @media(max-width: 769px) {
        height: 20%;
    }

    border-bottom: 1px solid #D3E0FF;

    .right-feed-card {
        // width: 330px;
        background: #F4F3FF;
        // background: #F5FAFF;
        border-radius: 5px;
        height: 100%;

        .user-avatar {
            // height: 1.8rem;
            // width: 2rem;
            height: 32px;
            // width: auto;
            width: auto;
            border-radius: 50%;
            // padding: 1%;
        }

        .user-name-provider-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
        }

        .social-logo {
            height: 20px;
            width: 20px;
        }

        .user_name {
            text-transform: capitalize;
        }

        .user_name,
        .date-time {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.12px;
            display: flex;
            align-items: center;
            color: #17173A;
        }

        .date-time {
            font-weight: 400;
            margin: 5px 0;
            font-size: 0.6875rem;
            color: #667085;
        }

        .phone-number {
            font-weight: 400;
            margin: 7px 0 0 0;
            font-size: 0.6875rem;
            color: #667085;
        }

        .footer {
            height: 50%;
            // background-color: #00C48C;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .footer-item {
                height: 100%;
            }

            border-top: 1.5px solid #D3E0FF;

            .title,
            .value {
                margin: 4px 0;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 0.01em;
                color: #667085;
                font-size: 12px;
            }
        }
    }
}

.whats-app-properties-wall {
    display: flex;
    flex-direction: row;
    padding: 3%;
    // height: 47%;
    border-bottom: 1px solid #D3E0FF;
    background: #F4F3FF;

    .properties-titles,
    .properties-values {
        // height: 100%;
        text-align: start;
        padding: 2% 1%;
        font-weight: 400;
        font-size: 0.75rem;
        color: #667085;
        display: flex;
        flex-direction: column;
        // gap: 10%;
        gap: 1.2rem;
    }

    .properties-titles {
        width: 30%;

        .titles {
            // border: 1px solid blue;
            height: 20%;
            font-weight: 500;
            font-size: 0.75rem;
            color: #667085;
            display: flex;
            align-items: center;
        }
    }

    .properties-values {
        width: 70%;

        .values {
            // border: 1px solid green;
            width: 100%;
            height: 20%;
            font-weight: 500;
            font-size: 0.75rem;
            color: #667085;
            display: flex;
            align-items: center;

            .value {
                width: fit-content;
                height: 100%;
                background: #D8E3FF;
                padding: 2% 5%;
                border-radius: 3px;
                display: flex;
                align-items: center;
                gap: 0.25rem;
            }

            .email-value,
            .telephone-value {
                width: fit-content;
                height: 100%;
                padding: 2% 5%;
                border-radius: 3px;
                display: flex;
                align-items: center;
                gap: 0.25rem;
                background: #FFFFFF;

                input {
                    border: none;
                    outline: none;
                    padding: 2% 0;
                    font-size: 0.75rem;
                    color: #667085;
                    height: 100%;
                    width: 100%;
                }
            }

            .channel-color,
            .assigned-color,
            .status-color {
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                }
            }

            .status-color {
                background: #F79009;
                border-radius: 50%;
            }

        }
    }
}

.bot-properties-wall {
    display: flex;
    flex-direction: row;
    padding: 3%;
    height: 47%;
    border-bottom: 1px solid #D3E0FF;
    background: #F4F3FF;

    .properties-titles,
    .properties-values {
        height: 100%;
        text-align: start;
        padding: 2% 1%;
        font-weight: 400;
        font-size: 0.75rem;
        color: #667085;
        display: flex;
        flex-direction: column;
        gap: 10%;
    }

    .properties-titles {
        width: 30%;

        .titles {
            // border: 1px solid blue;
            height: 20%;
            font-weight: 500;
            font-size: 0.75rem;
            color: #667085;
            display: flex;
            align-items: center;
        }
    }

    .properties-values {
        width: 70%;

        .values {
            // border: 1px solid green;
            width: 100%;
            height: 20%;
            font-weight: 500;
            font-size: 0.75rem;
            color: #667085;
            display: flex;
            align-items: center;

            .value {
                width: fit-content;
                height: 100%;
                background: #D8E3FF;
                padding: 2% 5%;
                border-radius: 3px;
                display: flex;
                align-items: center;
                gap: 0.25rem;
            }

            .email-value,
            .telephone-value {
                width: fit-content;
                height: 100%;
                padding: 2% 5%;
                border-radius: 3px;
                background: #FFFFFF;

                input {
                    border: none;
                    outline: none;
                    font-size: 0.75rem;
                    color: #667085;
                    height: 100%;
                    width: 100%;
                }
            }

            .channel-color,
            .assigned-color,
            .status-color {
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                }
            }

            .status-color {
                background: #F79009;
                border-radius: 50%;
            }

        }
    }
}

.bot-details-wall,
.whats-app-details-wall {
    height: 31%;

    @media(max-width: 769px) {
        height: 35%;
    }

    padding: 3%;
    background: #F4F3FF;

    .details-container {
        background: #FFFFFF;
        border-radius: 5px;
        padding: 3%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 5%;
        text-align: start;

        .details-title {
            height: 10%;
            font-weight: 600;
            font-size: 0.875rem;
            color: #344054;
        }

        .details-value {
            height: 90%;
            overflow: auto;
            font-weight: 500;
            font-size: 0.8rem;
            color: #344054;
            line-height: 1.2rem;
        }

    }
}

// ===== Bot CSS end ========

.conversation-details-lg,
.bot-conversation-details,
.whats-app-conversation-details {
    display: block;
    font-family: 'Graphik';
    font-style: normal;
    overflow: auto;

    @media (max-width: 769px) {
        display: none;
    }



    .inbox-wall {
        // height: 18% !important;
        font-family: 'Graphik';
        font-style: normal;

        .right-feed-card {
            height: 100% !important;
            overflow: hidden !important;

            .user-avatar {
                flex-shrink: 0;
                display: block;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                padding: 0;
                border: none;
                box-sizing: border-box;
            }

            .user-name-provider-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
            }

            .social-logo {
                height: 20px;
                width: 20px;
            }

            .user_name,
            .rating-social-email {
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.12px;
                display: flex;
                align-items: center;
                color: #17173A;
            }

            .rating-social-email {
                font-weight: 400;
                margin: 5px 0;
                font-size: 10px;
                color: #667085;

                .all-stars {
                    display: flex;
                    align-items: center;
                    gap: 5%;
                }
            }

            .footer {
                height: 60%;

                // background-color: #00C48C;
                div {
                    height: 100%;
                }

                .footer-item {
                    height: 100% !important;
                }

                border-top: 1.5px solid #D3E0FF;

                .title,
                .value {
                    margin: 4px 0;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: 0.01em;
                    color: #667085;
                    font-size: 12px;
                }

                // .value {
                //     font-size: 11px;
                // }
            }

            .star {
                height: 10px;
                width: 10px;
            }
        }
    }

    // .properties-wall,
    // .details-wall,
    // .inbox-wall {
    //     // margin: 0 0 2% 0 !important;
    // }

    .inbox-wall {
        &:first-child {
            margin-top: 0% !important;
        }
    }

    .properties-wall {
        height: 26% !important;
        font-family: 'Graphik';
        font-style: normal;

        .right-feed-card {
            height: 100% !important;
            overflow: hidden !important;

            .content {
                height: 88%;
                // padding: 0% 0 !important;
                // background: #00C48C;
            }

            .header-title {
                margin-top: 1%;
                height: 10%;
                font-weight: 600;
                // font-size: 14px;
                // line-height: 14px;
                // letter-spacing: 0.01em;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.12px;
                color: #17173A;
            }

            .req-res {
                display: flex;
                align-items: flex-start;
                // justify-content: flex-start;
                flex-direction: column;
                width: 25%;

                // border: 1px solid #D3E0FF;
                span {
                    display: block;
                    margin: 14.2% 0 !important;
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 500;
                }
            }

            .req-res-value {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                // border: 1px solid #D3E0FF;
                span {
                    margin: 6% 0;
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 500;
                }

                width: 60%;
                position: relative;

                span {
                    font-weight: 400;
                }

                .replier {
                    // width: 110px;
                    height: 25px;
                    font-size: 10px;
                    padding: 5px 10px;
                    position: absolute;
                    bottom: -35px;
                    margin-bottom: 5px;
                    background: #E4E7EC;
                    border-radius: 10px;

                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                    }
                }
            }

            .type-to-lock,
            .type-to-lock-value {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;

                // width: 30%;
                // border: 1px solid #D3E0FF;
                span {
                    margin: 10px 0 15px 0;
                    font-size: 11px;
                    // line-height: 12px;
                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 400;
                }

                .lock-unlock {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    height: 25px;
                    border: 1px solid #D3E0FF;
                    background: #E4E7EC;
                    border-radius: 10px;
                    padding: 0 18px;
                    position: relative;

                    span {
                        font-weight: 400;
                        font-size: 10px;
                        // line-height: 10px;
                        letter-spacing: 0.01em;
                        color: #667085;

                        img {
                            height: 10px;
                            margin: 0 0 2px 5px;
                        }
                    }
                }
            }

            .type-to-lock {

                // width: 20%;
                span {
                    font-weight: 500;
                    font-size: 12px;
                }

                &:last-child {
                    margin-bottom: 5px !important;
                    width: fit-content;
                    // display: flex;
                    // align-items: center;
                    // justify-content: flex-end;
                    margin-right: 5%;
                }

                .not-replied,
                .replied {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #F04438;
                    height: 25px;
                    border-radius: 15px;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 7px;
                    letter-spacing: 0.01em;
                    color: #17173A;
                    padding: 0 15px;
                }

                .replied {
                    background: #00C48C;
                    // background: #5CCE99;
                }
            }
        }

    }

    .details-wall {
        height: 28% !important;
        font-family: 'Graphik';
        font-style: normal;

        .right-feed-card {
            height: 100% !important;
            overflow: hidden !important;

            .content {
                height: 88%;
                // padding: 0% 0 !important;
                // background: #00C48C;
            }

            .header-title {
                margin-top: 1%;
                height: 10%;
                // font-size: 14px;
                // line-height: 14px;
                // letter-spacing: 0.01em;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.12px;
                color: #17173A;
            }

            .req-res {
                display: flex;
                align-items: flex-start;
                // justify-content: flex-start;
                flex-direction: column;
                width: 26%;

                // border: 1px solid #D3E0FF;
                span {
                    display: block;
                    margin: 14.2% 0 !important;
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 500;
                }
            }

            .req-res-value {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                // border: 1px solid #D3E0FF;
                span {
                    margin: 6% 0;
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 500;
                }

                width: 60%;
                position: relative;

                span {
                    font-weight: 400;
                }

                .replier {
                    // width: 110px;
                    height: 25px;
                    font-size: 10px;
                    padding: 5px 10px;
                    position: absolute;
                    bottom: -30%;
                    margin-bottom: 5px;
                    background: #E4E7EC;
                    border-radius: 10px;

                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                    }
                }
            }

            .type-to-lock,
            .type-to-lock-value {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;

                // width: 30%;
                // border: 1px solid #D3E0FF;
                span {
                    margin: 10px 0 15px 0;
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 400;
                }

                .lock-unlock {
                    width: fit-content;
                    height: 25px;
                    border: 1px solid #D3E0FF;
                    background: #E4E7EC;
                    border-radius: 10px;
                    padding: 0 18px;
                    position: relative;

                    span {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 10px;
                        letter-spacing: 0.01em;
                        color: #667085;
                        margin-bottom: 12px !important;

                        img {
                            height: 10px;
                            margin: 0 0 2px 5px;
                        }
                    }
                }
            }

            .type-to-lock {

                // width: 20%;
                span {
                    font-weight: 500;
                }

                &:last-child {
                    margin-bottom: 5px !important;
                    width: fit-content;
                    // display: flex;
                    // align-items: center;
                    // justify-content: flex-end;
                    margin-right: 5%;
                }

                .not-replied,
                .replied {
                    background: #F04438;
                    height: 25px;
                    border-radius: 15px;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 10px;
                    letter-spacing: 0.01em;
                    color: #17173A;
                    padding: 8px 18px;
                }

                .replied {
                    background: #00C48C;
                    // background: #5CCE99;
                }
            }
        }

    }
}

.bot-conversation-details,
.whats-app-conversation-details {
    display: block;
}

.conversation-details-mobile {
    display: flex;

    @media(min-width: 769px) {
        display: none !important;
    }

    background: #FFFFFF;
    padding: 8px;
    font-family: 'Graphik';
    font-style: normal;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .inbox-wall {
        background: #F4F3FF;
        font-family: 'Graphik';
        font-style: normal;

        .right-feed-card {
            height: 100% !important;
            overflow: hidden !important;

            .user-avatar {
                flex-shrink: 0;
                display: block;
                height: 32px;
                width: auto;
                border-radius: 50%;
                padding: 0;
                border: none;
                box-sizing: border-box;
            }

            .user-name-provider-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
            }

            .social-logo {
                height: 21px;
                width: 21px;
            }

            .user_name,
            .rating-social-email {
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;

                @media(max-height: 811px) {
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 10px;
                }

                display: flex;
                align-items: center;
                letter-spacing: 0.01em;
                color: #17173A;
            }

            .rating-social-email {
                font-weight: 400;
                margin: 5px 0;
                font-size: 10px;
                color: #667085;

                .all-stars {
                    display: flex;
                    align-items: center;
                    gap: 5%;
                }
            }

            .star {
                height: 10px;
                width: 10px;
            }

            .footer {
                height: 60%;

                // background-color: #00C48C;
                div {
                    height: 100%;

                    @media(max-height: 811px) {
                        height: 80%;
                    }
                }

                .footer-item {
                    height: 100% !important;
                }

                border-top: 1.5px solid #D3E0FF;

                .title,
                .value {
                    margin: 4px 0;
                    font-weight: 500;
                    line-height: 12px;

                    @media(max-height: 811px) {
                        margin: 2px 0;
                        font-size: 10px;
                        line-height: 10px;
                    }

                    letter-spacing: 0.01em;
                    color: #667085;
                    font-size: 12px;
                }

                .value {
                    font-size: 11px;
                }
            }
        }
    }

    .properties-wall,
    .details-wall,
    .inbox-wall {
        height: 20%;

        @media(max-width: 811px) {
            height: 20%;
        }

        background: #F4F3FF;
        border-radius: 5px;
        margin: 1% 0;
    }

    .inbox-wall {
        &:first-child {
            margin-top: 0% !important;
        }
    }

    .properties-wall {
        @media(max-width: 811px) {
            height: 25% !important;
        }
    }

    .details-wall {
        @media(max-width: 811px) {
            height: 30% !important;
        }
    }

    .properties-wall,
    .details-wall {
        height: 28%;
        font-family: 'Graphik';
        font-style: normal;

        .right-feed-card {
            height: 100% !important;
            overflow: hidden !important;

            .content {
                height: 88%;
                // padding: 0% 0 !important;
                // background: #00C48C;
            }

            .header-title {
                margin: 1% 0;
                height: 10%;
                font-weight: 600;
                font-size: 13px;
                line-height: 13px;

                @media (max-height: 811px) {
                    font-size: 11px;
                    line-height: 11px;
                }

                letter-spacing: 0.01em;
                color: #17173A;
            }

            .req-res {
                display: flex;
                align-items: flex-start;
                // justify-content: flex-start;
                flex-direction: column;
                width: 25%;
                margin-top: 2px;

                // border: 1px solid #D3E0FF;
                span {
                    display: block;
                    margin: 12% 0;
                    font-size: 12px;
                    line-height: 12px;

                    @media(max-height: 811px) {
                        margin: 10% 0;
                        font-size: 10px;
                        line-height: 10px;
                    }

                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 500;
                }
            }

            .req-res-value {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                // border: 1px solid #D3E0FF;
                span {
                    margin: 5% 0;
                    font-size: 12px;
                    line-height: 12px;

                    @media(max-height: 811px) {
                        margin: 4% 0;
                        font-size: 10px;
                        line-height: 10px;
                    }

                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 500;
                }

                width: 60%;
                position: relative;

                span {
                    font-weight: 400;
                }

                .replier {
                    // width: 110px;
                    height: 25px;
                    font-size: 10px;
                    padding: 5px 10px;
                    position: absolute;
                    bottom: -35px;
                    margin-bottom: 5px;
                    background: #E4E7EC;
                    border-radius: 10px;

                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                    }
                }
            }

            .type-to-lock,
            .type-to-lock-value {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;
                margin-top: 5px;

                // width: 30%;
                // border: 1px solid #D3E0FF;
                span {
                    margin: 10px 0 15px 0;

                    @media(max-height: 811px) {
                        margin: 2px 0 10px 0;
                        font-size: 10px;
                        line-height: 10px;
                    }

                    letter-spacing: 0.01em;
                    color: #667085;
                    font-weight: 400;
                }

                .lock-unlock {
                    width: fit-content;
                    height: 25px;
                    border: 1px solid #D3E0FF;
                    background: #E4E7EC;
                    border-radius: 10px;
                    padding: 0 18px;
                    position: relative;

                    span {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 0;
                        letter-spacing: 0.01em;
                        color: #667085;
                        // margin-bottom: 12px !important;

                        img {
                            height: 10px;
                            margin: 0 0 2px 5px;
                        }
                    }
                }
            }

            .type-to-lock {

                // width: 20%;
                span {
                    font-weight: 500;
                }

                &:last-child {
                    margin-bottom: 5px !important;
                    width: fit-content;
                    // display: flex;
                    // align-items: center;
                    // justify-content: flex-end;
                    margin-right: 5%;
                }

                .not-replied,
                .replied {
                    background: #F04438;
                    height: 25px;
                    border-radius: 15px;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 7px;
                    letter-spacing: 0.01em;
                    color: #17173A;
                    padding: 8px 18px;
                }

                .replied {
                    background: #00C48C;
                    // background: #5CCE99;
                }
            }
        }

    }

}