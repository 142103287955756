.ant-table-thead {
    background: #fff;

    p {
        color: #17173A !important;
        font-size: 0.9rem !important;
        font-weight: 600 !important;

        @media(max-width: 1400px) {
            font-size: 0.75rem !important;
        }
    }

    // background: linear-gradient( 90deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
    // background: transparent !important;
    // vertical-align: auto !important;
}

.ant-table-thead tr th {
    // background: #fff;
    // background: linear-gradient( 90deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
    background: transparent !important;
    // vertical-align: auto !important;
    color: #000;
}

.ant-table-cell {
    padding: 3% 2% !important;
}

.ant-table-tbody>tr>td {

    p {
        font-size: 0.9rem !important;

        @media(max-width: 1400px) {
            font-size: 0.75rem !important;
        }
    }
}

.ant-table table {
    font-size: 0.875rem;
    margin: 0;
}