.ant-modal-footer {
    border-top: none !important;
    padding: 0 !important;
}

.user-settings-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5%;

    // border: 2px solid #0d4fe7;
    @media(max-width: 769px) {
        gap: 15px !important;
        padding-top: 10%;
    }

    .invite-button {
        position: absolute;
        top: 11%;
        right: 5%;
        width: -moz-fit-content;
        width: fit-content;

        @media(max-width: 769px) {
            position: relative;
            top: 0;
            right: 0;
        }

        button {
            width: 10vw;
            height: 5vh;

            @media(max-width: 769px) {
                width: 30vw;
                // height: 10vh;
                padding: 5% 0;
            }

            background: #D8E3FF;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    .search-input {
        width: 65%;
        height: 8.5%;
        // padding-left: 2%;
        border-radius: 3px;
        border: 1px solid var(--border-big-button, #A4BCFD);
        background: var(--bg-white, #FFF);
        color: var(--text-placeholder, #98A2B3);

        .search-icon {
            margin-right: 5px;
        }

        input {
            font-size: 0.9rem;
            font-weight: 400;

            @media(max-width: 1400px) {
                font-size: 0.75rem;

                @media(max-width: 769px) {
                    // width: 100% !important;
                    font-size: 0.9rem;
                }
            }
        }

    }

    .user-table {
        height: 80%;

        .super-admin {
            text-align: center;
            border-radius: 3px;
            border: 1px solid var(--border-inbox, #D3E0FF) !important;
            padding: 4% 2%;
            color: #17173A;
            font-weight: 400;
            font-size: 0.8rem;

            @media(max-width: 1400px) {
                font-size: 0.6rem !important;

                @media(max-width: 769px) {
                    font-size: 0.8rem;
                }
            }
        }
    }
}

.menu-container {
    width: 100%;
    display: block;
    height: 100%;


    .form-container {
        height: 100%;

        @media(max-width: 769px) {
            height: 80vh !important;
            padding: 1% 3%;
            // border: 2px solid #D3E0FF;
        }

        width: 100%;

        padding: 2.5% 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 8%;

        @media(max-width: 769px) {
            gap: 4%;
        }


        .form-group {
            font-family: 'Graphik';
            font-style: normal;
            position: relative;
            height: 17% !important;

            @media(max-width: 769px) {
                height: 14% !important;
            }

            color: #17173A;
            // border: 1px solid red;

            // justify-content: center;
            label,
            span {
                font-weight: 400;
                font-size: 0.8rem;

                @media(max-width: 1400px) {
                    font-size: 0.6rem;

                    @media(max-width: 769px) {
                        font-size: 0.8rem;
                    }
                }

                color: #667085;
            }

            label {
                margin-bottom: 1%;
            }

            .input-container {
                position: relative;
                // margin-top: 1%;
                height: 80%;
                // border-left: 4px solid #1570EF;
                border-left: none;
                // box-shadow: 0px 0px 5px rgba(23, 23, 58, 0.15);
                box-shadow: none;
                // border-radius: 0px 3px 3px 0px;
                border-radius: 5px;
                border: 1px solid var(--border-inbox, #D3E0FF);
                padding: 1% 2% 1% 4%;
                display: flex;
                align-items: center;
                gap: 15%;

                input,
                select,
                .image-label {
                    // height: 50px;
                    width: 75%;
                    height: 50%;
                    margin: auto 0;
                    border: none;
                    outline: none;
                    font-weight: 400;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;

                        @media(max-width: 769px) {
                            width: 100% !important;
                            font-size: 0.9rem;
                        }
                    }

                    &:focus {
                        transition: all 0.3s ease-out;
                        border-bottom: 1px solid #A4BCFD;
                    }
                }

                input::placeholder {
                    font-weight: 400;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;

                        @media(max-width: 769px) {
                            font-size: 0.9rem;
                        }
                    }

                    line-height: 0.9rem;
                    color: #667085;
                }

                .border-none {
                    border: none !important;

                    &:focus {
                        border: none !important;
                    }
                }

                .input-error {
                    border-bottom: 1px solid #fd1818 !important;
                }

                .add-edit-save-icon {
                    position: absolute;
                    right: 5%;
                    top: 35%;
                    height: auto;
                    width: auto;
                    // width: 4.5%;
                    cursor: pointer;
                }

                .disabled {
                    cursor: not-allowed;
                }

                .add-edit-save-btn {
                    position: absolute;
                    right: 2%;
                    top: 30%;
                    height: 50%;
                    // width: 20%;
                    padding: 2% 8%;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;

                        @media(max-width: 769px) {
                            font-size: 0.9rem;
                        }
                    }

                    // line-height: 12px;
                    color: #17173A;
                    background: #E4E7EC;
                    border-radius: 25px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    transition: all 0.3s ease-out;

                    &:hover {
                        background: #c9c9ca;
                    }
                }
            }


            .image-label {
                // height: 50px;
                outline: none;
            }


            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                box-shadow: 2px 2px 12px 0px #0000001a;
                width: 100%;
                height: 50px;
                padding: 8px 11px;
            }

            position: relative;

            .delete {
                position: absolute;
                top: 0;
                // right: 100*0.07353%;
                right: 0;
                cursor: pointer;
                z-index: 1;
                height: auto;
                width: auto;
                // width: 10%;
                // width: 8.53px;
                // height: 8.52px;
                // color: #8d8c8c;
                transition: all 0.5s ease-in-out;

                &:hover {
                    color: #ff0000;
                }
            }

            .host-guest-container {
                // margin-top: 2%;
                display: flex;
                height: 75%;

                .host-button,
                .guest-button {
                    padding: 2% 5%;
                    background: #FFFFFF;
                    box-shadow: 0.8px 0.8px 10px rgba(23, 23, 58, 0.1);
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;

                        @media(max-width: 769px) {
                            font-size: 0.9rem;
                        }
                    }

                    letter-spacing: 0.01em;
                    color: #17173A;
                    transition: all 0.5s ease-out;

                    // &:hover {
                    //     // background: #D8E3FF;
                    // }
                }

                .button-active {
                    background: #D8E3FF;

                }
            }
        }

        .delete-account {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3%;

            @media(max-width: 769px) {
                margin-top: 20%;
            }

            button {
                width: fit-content;
                margin: auto;
                padding: 2% 5%;
                font-weight: 600;
                font-size: 0.9rem;
                color: #17173A;

                @media(max-width: 1400px) {
                    font-size: 0.75rem;

                    @media(max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }

                border-radius: 0.25rem;
                border: 1px solid #FFC9B7;
                background: #FFF;
                outline: none;
                cursor: pointer;
                transition: all 0.3s ease-out;

                &:hover {
                    background: #FFC9B7;
                }
            }
        }
    }
}


label {
    margin-left: 0;
}