.nice-btn {
    padding: 3px 5px;
    margin: 0 5px;
    background-color: #e1e7ed;
    border-color: #172b70 !important;
    border-radius: 5px;

}

.nice-btn:hover {
    // border-color: #172b70;
    background-color: #172b70;
    color: #fff;
}

.nice-btn:focus {
    // background: linear-gradient( 180deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
    // background-color: #172b70;
    color: inherit;
    // background-color: #182B72;
}

.ant-modal-close {
    color: #172B70 !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    // opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    padding: 16px;
    cursor: pointer;
    transition: color .3s;
    z-index: 1;

    &:hover {
        color: #26dae0 !important;
    }
}

.ant-modal-header {
    border-bottom: none !important;
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.whats-app-info-modal {
    width: 700px !important;
    font-family: 'Graphik';

    @media(max-width: 768px) {
        width: 95%;
    }

    .card-container {
        width: 85%;
        margin: 0 auto 1rem auto;
        // min-height: 25%;
        height: fit-content;
        border-radius: 5px;
        border: 0.5px solid var(--icon-general, #ABB9CC);
        // padding: 4%;
        display: flex;
        flex-direction: row;

        @media (max-width: 1400px) {
            padding: 1% 1.5%;

            @media (max-width: 769px) {
                padding: 3%;
                height: fit-content;
                gap: 3%;
                margin-bottom: 5%;
                flex-direction: column;
                align-items: center;
            }
        }

        .logo-container {
            width: 7%;
            // height: fit-content;
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            img {
                // width: 5vw;
                margin-top: 20%;
                width: auto;
                height: auto;
                text-align: center;
                // border-radius: 50%;
            }
        }

        .card-content {
            padding: 1% 1%;
            // border: 1px solid red;
            width: 95%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            // gap: 1%;

            @media(max-width: 769px) {
                gap: 5%;
            }

            .card-title {
                // margin-bottom: ;
                width: 100%;
                font-size: 0.9rem;
                line-height: inherit;
                font-weight: 600;

                @media (max-width: 1400px) {
                    font-size: 0.875rem;
                    // line-height: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                        // line-height: 0.9rem;
                        text-align: center;
                    }
                }

                color: #17173A;
            }

            .description-container,
            .card-footer {
                margin-top: 1%;
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 5%;

                @media (max-width: 769px) {
                    flex-direction: column;
                    align-items: center;
                    // justify-content: center;
                }

                .card-description {
                    // width: 70%;
                    color: #000;
                    font-weight: 400;
                    line-height: 1.25rem;
                    font-size: 0.875rem;

                    @media (max-width: 1680px) {
                        font-size: 0.875rem;

                        @media (max-width: 1400px) {
                            font-size: 0.875rem;

                            @media (max-width: 769px) {
                                width: 100%;
                                font-size: 0.9rem;
                                text-align: center;
                            }
                        }
                    }
                }

                .button-container {
                    width: 25%;

                    @media(max-width: 769px) {
                        width: 100%;
                        height: 6vh;
                    }

                    padding: 1% 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-end;

                    button {
                        width: 100%;
                        height: 5vh;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 2px;
                        background: var(--bg-active-text-bg, #D8E3FF);
                        color: var(--text-body, #101828);
                        font-weight: 400;
                        font-size: 0.825rem;

                        @media (max-width: 1400px) {
                            font-size: 0.625rem;

                            @media (max-width: 769px) {
                                font-size: 0.825rem;
                            }
                        }
                    }
                }
            }

            .card-footer {
                justify-content: space-between;
                align-items: center;
                gap: 0;
                padding: 0 0.2% 0 0;
                // margin: 2% 0 1% 0;

                @media(max-width: 769px) {
                    flex-direction: column-reverse;
                    gap: 5%;
                }

                .card-footer-button {
                    width: fit-content;
                    padding: 0 3%;
                    height: 4.2vh;
                    outline: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.3125rem;
                    border: 0.5px solid var(--button-active-button-outline, #175CD3);
                    background: var(--bg-active-text-bg, #FFF);
                    color: var(--text-body, #000);
                    font-weight: 400;
                    font-size: 0.825rem;

                    @media (max-width: 1400px) {
                        font-size: 0.625rem;

                        @media (max-width: 769px) {
                            width: 100%;
                            font-size: 0.825rem;
                        }
                    }
                }

                p {
                    color: var(--text-body, #17173A);
                    font-weight: 400;
                    font-size: 0.825rem;

                    @media (max-width: 1400px) {
                        font-size: 0.625rem;

                        @media (max-width: 769px) {
                            font-size: 0.825rem;
                        }
                    }
                }
            }
        }
    }
}

// .ant-modal-body {
//     min-height: 30vh;
// }