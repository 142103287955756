.non-preview-files-carousel-container {
    background: #EEF4FF;
    padding: 8px 10px;
    margin: 5px 0;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
    }

    .file-container {
        // style={{ display: 'flex', alignItems: 'center', gap: 10 }}
        // border: 2px solid red;
        padding: 8px 15px;
        border-radius: 5px;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-part {
            display: flex;
            align-items: center;
            gap: 10px;

            p {
                margin: 0 !important;
                padding: 0;
            }

            .header-title {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .file-type-size {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }

        }

        .right-part {
            .preview {
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
}

.preview-files-carousel-container {
    position: relative;
    background: #FFFFFF;
    padding: 50px 10px;
    // margin: 5px 0;
    // border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
    }

    .preview-cross {
        position: absolute;
        top: 10px;
        right: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    .file-container {
        width: 80%;
        margin: auto;
        overflow: hidden;

        img,
        video {
            // width: 100%;
            width: 'auto';
            height: 220px;
        }
    }
}