.notification-container {
    width: 390px;
    // min-height: 300px;
    background: #FFF;
    box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.15);
    position: absolute;
    right: 16px;
    top: 80px;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .notification-header {
        border-bottom: 1px solid #ECE9F1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 20px;

        .notification-title {
            text-transform: capitalize;
            color: #17173A;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            font-family: 'Graphik';
            display: flex;
            justify-content: space-between;

            span {
                background: #172B70;
                border-radius: 3px;
                width: 22px;
                height: 22px;
                text-align: center;
                text-transform: capitalize;
                color: #F5F5F5;
                font-size: 12px;
                display: flex;
                justify-content: center;
                margin: 0 6px;
                align-items: center;
            }
        }

        .notification-menu {
            width: 30px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            .notification-icon {
                display: flex;
                flex-direction: column;

                span {
                    background: #C4C4C4;
                    width: 3px;
                    height: 3px;
                    border-radius: 50px;
                    margin: 2px 0;
                    display: flex;
                }
            }
        }
    }

    .notification-list-container {
        border-bottom: 1px solid #ECE9F1;
        display: flex;
        flex-direction: row;
        padding: 10px 16px;
        background: #F1F8FD;

        .notification-icon {
            display: block;
        }

        .notification-item-container {
            display: flex;
            flex-direction: column;

            .notification-item-wrapper {
                display: flex;
                flex-direction: column;
                padding: 0 20px;

                p {
                    color: #333333;
                    font-size: 10px;
                    font-family: 'Graphik';
                    text-align: left;
                    margin: 0;
                }

                .notification-date-selected {
                    display: flex;

                    .notification-date {
                        i {
                            color: #CDCDCD;
                            font-size: 11px;
                        }

                        span {
                            font-family: 'Graphik';
                            color: #A3A3A3;
                            font-size: 9px;
                            padding: 0 5px;
                        }
                    }

                    .notification-selected {}
                }
            }
        }
    }

    .no-notifications {
        padding: 10px 0;
    }
}

.dialog {
    // display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10000;
    /* Sit on top */
    padding-top: 10%;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0);
    /* Fallback color */
    // background-color: rgba(0, 0, 0, 0.2);
    background-color: transparent;

    /* Black w/ opacity */
    .dialog_container {
        min-width: 18vw;
        // width: 280px;
        // margin: 20px auto 0 auto;
    }
}

.error,
.success,
.modal_title,
.modal_content,
.modal_text,
.warn_modal_text,
.modal_button,
.cancel_btn,
.continue_btn {
    font-family: 'Inter';
    font-style: normal;
}

.modal_content {
    img {
        width: 15px;
        height: 14px;
        margin-bottom: 2px;

        @media (max-width: 1400px) {
            width: 13px;
            height: 12px;

            @media (max-width: 1024px) {
                width: 17px;
                height: 16px;

                @media (max-width: 769px) {
                    width: 9px;
                    height: 8px;
                }
            }
        }
    }

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.01em;

        @media (max-width: 1400px) {
            font-size: 14px;
            line-height: 14px;

            @media (max-width: 1024px) {
                font-size: 18.4578px;
                line-height: 18px;

                @media (max-width: 769px) {
                    font-size: 9.37728px;
                    line-height: 9px;
                }
            }
        }
    }
}

.error,
.success {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.01em;
}