.custom-carousel {
    align-items: center;
    height: 70vh;
    width: 100%;
    overflow: hidden;

    @media(max-width: 769px) {
        height: auto;
        overflow: visible;
    }

    .carousel-btn {
        width: 2.5vw;
        height: 8vh;
        background: #344054;
        // padding: 2% 2%;
        transition: all 0.3s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            background: #47546d;
        }

        // img {
        //     height: 100%;
        //     width: 100%;
        // }

        @media(max-width: 769px) {
            // display: none;
            width: auto;
        }
    }

    .content-carousel-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 65vh;
        overflow: hidden;
        gap: 5%;
        width: 100%;
        margin: 0 auto;
        padding: 2% 0;

        @media(max-width: 769px) {
            height: 100%;
            width: 100% !important;
            flex-direction: row;
            justify-content: center;
            padding: 0;
        }

        .carousel-video {
            z-index: 9;
            // height: 70%;
            height: auto;
            max-height: 100%;
            border-radius: 5px;
            // width: auto;
            width: 95%;
            margin: 0 auto;

            @media(max-width: 769px) {
                width: 100%;
                height: 250px;
                margin-bottom: 5%;
            }
        }

        .carousel-image {
            z-index: 9;
            // height: 70%;
            // width: 95%;
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
            border-radius: 5px;
            margin: 0 auto;

            @media(max-width: 769px) {
                width: 100%;
                height: 250px;
                margin-bottom: 5%;
            }
        }


    }
}

.mobile-seo-container {
    @media(max-width: 576px) {
        padding-bottom: 150px !important;
    }
}

.preview-footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px solid var(--border-inbox, #0c52f4);
    position: fixed;
    bottom: 50%;
    left: 0;
}

.seo-content-container {
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: var(--bg-white, #FFF);

    .seo-content-body,
    .biopage-seo-container,
    .SEO-seo-container,
    .versions-seo-container,
    .properties-seo-container {
        // height: 55vh;
        height: 100%;

        @media(max-width: 769px) {
            height: 100%;
        }

        width: 100%;
        padding: 0 2% 6% 5%;
        // border: 2px solid var(--border-inbox, #0c52f4);
        // margin: 4% 4.5% 2% 4.5%;

    }

    .biopage-seo-container,
    .SEO-seo-container,
    .versions-seo-container,
    .properties-seo-container {
        padding: 0 0 0 0 !important;

        @media(max-width: 769px) {
            padding: 0 3% 0 0 !important;
            height: fit-content;
        }

        .form-group {
            text-align: left;
            margin-top: 5%;

            .form-label {
                font-size: 0.85rem;
            }

            .form-input {
                border-radius: 3px;
                // border: 1px solid var(--border-inbox, #D3E0FF);
                width: 100%;
                padding: 1.5% 2.5%;
                border-radius: 3px;
                font-weight: 400;
                height: 50px;
                // height: 8.5vh;

                font-size: 0.9rem;

                @media(max-width: 1400px) {
                    font-size: 0.75rem;

                    @media(max-width: 769px) {
                        margin-top: 5% !important;
                        height: 6.5vh;
                        padding: 1.5% 2.5%;
                        font-size: 0.75rem;
                    }
                }
            }

            .form-textarea,
            .property-textarea {
                // margin-top: 7% !important;
                border-radius: 3px;
                // border: 1px solid var(--border-inbox, #D3E0FF);
                height: 22vh;
                width: 100%;
                resize: none;
                padding: 1.5% 2.5%;
                border-radius: 3px;
                font-size: 0.9rem;
                font-weight: 400;

                @media(max-width: 1400px) {
                    font-size: 0.75rem;

                    @media(max-width: 769px) {
                        margin-top: 5% !important;
                        font-size: 0.75rem;
                    }
                }
            }

            .property-textarea {
                height: 15vh !important;
            }
        }

        .version-content-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid var(--border-inbox, #D3E0FF);

            padding: 2% 0;

            &:last-child {
                margin-bottom: 5%;
            }

            .img-container {
                height: 4vh;
                width: auto;
                border-radius: 50%;
                background: #14B0BF;

                img {
                    cursor: pointer;
                    height: 100%;
                    width: 100%;
                }
            }

            img {
                cursor: pointer;
            }

            .version-content-info {
                padding: 0 2%;
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: left;

                .title {
                    color: var(--text-body, #101828);
                    font-weight: 500;
                    font-size: 0.9rem;
                    letter-spacing: 0.009rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;
                        letter-spacing: 0.0075rem;

                        @media(max-width: 769px) {
                            font-size: 0.9rem;
                            letter-spacing: 0.009rem;
                        }
                    }
                }

                .user-date {
                    color: var(--text-timestamp, #667085);
                    font-size: 0.75rem;
                    font-weight: 400;
                    margin: 0;
                    // letter-spacing: 0.00688rem;
                }
            }

        }

        .property-content {
            width: 100%;
            // height: 3vh !important;
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 3%;
            color: #000;
            font-weight: 500;
            font-size: 0.9rem;
            letter-spacing: 0.009rem;

            @media(max-width: 1400px) {
                font-size: 0.75rem;
                letter-spacing: 0.0075rem;

                @media(max-width: 769px) {
                    font-size: 0.75rem;
                    letter-spacing: 0.0075rem;
                }
            }

            .keys,
            .values {
                // padding: 5% 0;
                padding: 10px 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 12px;

                span {
                    margin: 0.4rem 0;
                }

            }

            .keys {
                // width: 30%;
                // width: 40%;
                width: 50%;

                @media(max-width: 769px) {
                    // width: 53%;
                    width: 55%;
                }
            }

            .values {
                width: 100%;
            }

        }


    }

    .properties-seo-container {
        .form-group {
            .form-input {
                margin-top: 2% !important;
            }

            .form-textarea {
                margin-top: 0% !important;
                margin-bottom: 3% !important;
            }
        }
    }

    .seo-content-tabs {
        // height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.5px solid var(--border-inbox, #D3E0FF);
        // padding: 4% 5% 2% 5%;
        padding: 2% 5% 2% 5%;

        .seo-content-tab,
        .seo-content-tab-active {
            cursor: pointer;
            color: #000;
            font-size: 0.75rem;
            font-weight: 500;
            border-bottom: 2px solid transparent;
            padding: 0.5%;
            transition: all 0.3s ease-in-out;
        }

        .seo-content-tab-active {
            border-bottom: 2px solid var(--button-active-button-outline, #175CD3);
        }
    }
}