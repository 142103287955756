.form-template-container {
    width: 100%;
    overflow: hidden;

    .form-textarea {
        margin-top: 3.5%;
        width: 100%;
        resize: none;
        padding: 1.5%;
        border-radius: 3px;
        border: 1px solid var(--icon-general, #ABB9CC);

        font-size: 0.9rem;
        font-weight: 400;

        @media(max-width: 1400px) {
            font-size: 0.75rem;

            @media(min-width: 768px) {
                font-size: 0.9rem;
            }
        }

        &::placeholder {
            color: var(--text-placeholder, #98A2B3);
            font-size: 0.9rem;

            @media(max-width: 1400px) {
                font-size: 0.75rem;

                @media(min-width: 768px) {
                    font-size: 0.9rem;
                }
            }
        }
    }

    .language-category-container {
        display: flex;
        flex-direction: row;
        gap: 5%;
        margin-top: 3.5%;
        width: 100%;
        text-align: center;

        font-size: 0.9rem;
        font-weight: 400;

        @media(max-width: 1400px) {
            font-size: 0.75rem;

            @media(max-width: 769px) {
                font-size: 0.9rem;
                flex-direction: column;
                gap: 1rem;
            }
        }

        .unknown-text {
            @media(max-width: 769px) {
                display: none;
            }
        }

        .language-zone,
        .category-zone {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

        }
    }

    .back-container {
        width: 100%;
        padding: 2% 3% 2% 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        // gap: 5%;
        // margin-bottom: 2%;

        .search-input {
            width: 80%;
            margin: 0 auto;
            height: 8.5%;
            // padding-left: 2%;
            border-radius: 3px;
            border: 1px solid var(--border-big-button, #A4BCFD);
            background: var(--bg-white, #FFF);
            color: var(--text-placeholder, #98A2B3);

            .search-icon {
                margin-right: 5px;
            }

            input {
                font-size: 0.9rem;
                font-weight: 400;

                @media(max-width: 1400px) {
                    font-size: 0.75rem;

                    @media(max-width: 769px) {
                        // width: 100% !important;
                        font-size: 0.9rem;
                    }
                }
            }

        }

        img {
            cursor: pointer;
        }
    }

    .header-title {
        font-size: 1.2rem;
        font-weight: 500;
        color: #17173A;
        padding-bottom: 0.5rem;
    }

    .form {
        width: 100%;
        // height: 20vh;
        display: flex;
        flex-direction: column;

        gap: 1.5rem;
        border-radius: 0px 5px 5px 0px;
        border-left: 5px solid var(--button-right-nav-bar, #1570EF);
        background: var(--bg-inbox-selection, #EEF4FF);
        padding: 3.5% 3% 4.5% 3%;

        .input-group {

            width: 100%;
            position: relative;

            .ant-row {
                width: 100%;
                height: 100%;
            }

            margin: 0;

            input {
                width: 100%;
                height: 100%;
                padding: 1% 0;
                border: none;
                border-bottom: 0.5px solid var(--icon-focus, #344054);
                background: transparent;
                outline: none;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.75rem;
                        width: 100%;
                    }
                }
            }

            .image-label {
                position: absolute;
                top: 20%;
                right: 1%;
                cursor: pointer;
                color: #17173A;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    .variable-input-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        width: 100%;
        position: relative;
        margin: 0.5rem 0;

        label {
            font-size: 0.75rem;
            color: #667085;
        }

        .variable-input {
            border-radius: 4px;
            padding: 1.6% 3%;
        }
    }

    .form-button-container {
        margin-top: 4%;
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5%;

        button {
            width: fit-content;
            padding: 0 5%;
            height: 5vh;
            background: #D8E3FF;
            border: none;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #17173A;
            font-size: 0.9rem;
            font-weight: 400;

            @media (max-width: 1400px) {
                font-size: 0.75rem;

                @media (max-width: 769px) {
                    font-size: 0.9rem;
                }
            }
        }
    }

}