.bot-settings-gap {
    gap: 5% !important;
}

.border-wrapper {
    .form-group {

        input,
        select,
        .image-label {
            height: 9vh;
            box-shadow: 2px 2px 12px 0px #0000001a;
            outline: none;
        }

        position: relative;

        button {
            position: absolute;
            top: 15px;
            right: 25px;
            width: 152px;
            height: 30px;
            background: #edeff3;
            border: 1px solid #e5e5e5;
            outline: none;
            cursor: pointer;

            &:hover {
                background: #b6bac0;
            }
        }


        @media (max-width: 769px) {
            button {
                width: 100px;
                font-size: 12px;
            }

            input::placeholder,
            select::placeholder {
                font-size: 12px;
            }
        }
    }

    .form-input-field {
        -webkit-appearance: none !important;
        -moz-appearance: none;
    }

    &:nth-child(1) {
        .border-custom {

            input,
            select,
            .image-label {
                border-left: 3.5px solid #65b9fc;
            }
        }
    }

    &:nth-child(2) {
        .border-custom {

            select,
            input,
            .image-label {
                border-left: 3.5px solid #67b638;
            }
        }
    }

    &:nth-child(3) {
        .border-custom {
            input {
                border-left: 3.5px solid #ffbe74;
            }
        }
    }

    &:nth-child(4) {
        .border-custom {
            input {
                border-left: 3.5px solid #ffe065;
            }
        }
    }

    &:nth-child(5) {
        .border-custom {
            input {
                border-left: 3.5px solid #2582f5;
            }
        }
    }

    &:nth-child(6) {
        .border-custom {
            input {
                border-left: 3.5px solid #bcea6c;
            }
        }
    }
}