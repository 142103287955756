%button {
    border-radius: 4px;
    // font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    text-align: center;
    padding: 4px 10px;
}

%button-md {
    border-radius: 4px;
    // font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 4px 20px;
}

%button-small {
    border-radius: 4px;
    // font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    padding: 4px 18px;
}

button {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 36px;
    letter-spacing: 0.15px;
    border-radius: 4px;
    outline: none;
    border: none;
    // min-width: max-content;
    position: relative;

    &:disabled {
        opacity: 0.6;
    }
}

.primary {
    @extend %button;
    background: #172B70;
    color: #FFFFFF;
}

.md {
    @extend %button-md;
}

.secondary {
    @extend %button;
    // background: #E1E7ED;
    background: #D8E3FF;
    color: #172B70;
    white-space: nowrap;
}

.secondary-btn {
    @extend %button;
    // min-width: 7vw;
    font-family: "Graphik" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;

    @media(max-width: 1600px) {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }

    height: 5vh;
    padding: 2% 8%;
    letter-spacing: 0.01em;
    background: #D8E3FF;
    white-space: nowrap;
    color: #17173A;
    border: 1px solid transparent;
    transition: all 0.5s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        background: #EEF4FF;
        /* Button / Active Button Outline */

        border: 1px solid #175CD3;
        // border-radius: 13px;
    }

    @media (max-width: 769px) {
        height: 5vh;
        padding: 2% 5%;
        border-radius: 4px;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 0.750rem;
        letter-spacing: 0.01em;

        color: #000000;
    }
}

.secondary-border {
    @extend %button;
    background: #E1E7ED;
    color: #172B70;
    border: 1px solid #172B70;
}

.transparent {
    @extend %button;
    background: #FFF;
    color: #172B70;
    border: 1px solid #172B70;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
}

.tertiary {
    @extend %button;
    background: #FFF;
    color: #AFB6CE;
    border: 1px solid #AFB6CE;
}

.feedback-tertiary {
    @extend %button-small;
    background: #FFF;
    color: #1a73e8;
    border: 1px solid #a9acaa;
}

.upload-secondary {
    @extend %button;
    background: #E1E7ED;
    color: #172B70;
}

.hover {
    &:hover {
        background: #2582F5 0% 0% no-repeat padding-box !important;
        color: #FFFFFF !important;
        transition: linear 0.3s;
    }
}