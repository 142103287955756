.modal-container{
    font-family: 'Graphik';
    font-style: normal;
    // margin-top: 10px;
    .header{
        font-weight: 600;
        font-size: 16px;
        // line-height: 5px;
        color: #171923;
        padding-bottom: 4px;
        border-bottom: 1px solid #E1E1EE;;
    }
    .sub-title{
        margin: 5px 0;
        font-weight: 500;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        gap: 10px;
        font-size: 14px;
        line-height: 14px;
    
        color: #2E0B34;
        .number{
            align-items: center;
            padding: 3px 4px;
            background: #E1ECFF;
            border-radius: 2px;
        }
    }
    .description{
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #171923;
        margin: 8px 0;
        // text-align: justify;
    }
    .input-container{
        width: 100% !important;
        margin-bottom: 8px;
        input{
            padding-left: 15px;
            box-sizing: border-box;
            width: 100% !important;
            height: 38px;
            outline: none;
            background: #FFFFFF;
            font-size: 12px;
            border: 1px solid #D3E0FF;
            border-radius: 4px;
        }
        // focus input
        input:focus{
            outline: none;
            border: 1px solid #1570EF;
            box-shadow: 0px 0px 0px 2px rgba(21, 112, 239, 0.2);
        }
    }
    .next, .finish{
        padding: 5px 12px;    
        width: 100%;
        height: 34px;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #FFFFFF;
        background: #1570EF;
        border-radius: 4px;
        font-weight: 500;
        outline: none;
        border: none;
    }
    .finish{
        // background: #2E0B34;
        background: #D8E3FF;
        color: #17173A;
    }
    .ant-modal-header {
        padding: 8px 10px !important;
    }
    .ant-modal-body {
        padding: 8px 20px !important;
    }
    

}