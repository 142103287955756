.mobile-drawer-header {
    font-family: 'Graphik';
    font-style: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .logo-title {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 20px;
            height: 20px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: #17173A;
        }
    }

    .mobile-cross-icon {
        cursor: pointer;
        width: 20px;
        height: 20;
        transition: all 0.5s ease-in-out;

        &:hover {
            opacity: 0.5;
        }
    }
}

.mobile-drawer-body {
    font-family: 'Graphik';
    font-style: normal;
    // padding: 10px 0;

    .mobile-page-active {
        // active    
        background: #D8E3FF;
        border: 1px solid #E5E5E5;
    }

    ul {
        // list style none;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            margin: 15px 0;
            height: 40px;
            width: 80%;
            padding: 12px 0 15px 25px;
            border-radius: 0px 20px 20px 0px;

            img {
                width: 20px;
                height: 20px;
            }

            p {
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #17173A;
            }
        }
    }
}

.mobile-drawer-footer {
    font-family: 'Graphik';
    font-style: normal;
    position: absolute;
    height: 48px;
    width: 80%;
    left: 0;
    bottom: 3%;

    // border: 1px solid #E5E5E5;
    .mobile-drawer-footer-container {
        height: 100%;
        padding: 5px 0 5px 25px;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .mobile-drawer-footer-active {
        // active
        background: #D8E3FF;
        border: 1px solid #E5E5E5;
    }

    img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    p {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #17173A;
    }
}

.nav-wrapper {
    font-family: 'Graphik';
    font-style: normal;
    // background: linear-gradient( 90deg, rgb(0,66,124) 0%, rgb(12,45,91) 13%, rgb(23,23,58) 100%);
    // background: #17173A;
    // background: -moz-linear-gradient( 90deg, rgb(0,66,124) 0%, rgb(12,45,91) 13%, rgb(23,23,58) 100%);
    background: -webkit-linear-gradient(90deg, rgb(0, 66, 124) 0%, rgb(12, 45, 91) 13%, rgb(23, 23, 58) 100%);
    // background: -ms-linear-gradient( 90deg, rgb(0,66,124) 0%, rgb(12,45,91) 13%, rgb(23,23,58) 100%);

    width: 4.3%;

    @media(max-width: 769px) {
        width: 70px;
    }

    min-height: 100vh;
    position: fixed;
    z-index: 1000;
    position: -webkit-sticky;

    // overflow: auto;
    // top: 5%;
    nav {
        height: 100vh;

        .logo-icon {
            cursor: pointer;
            width: 45%;
            height: auto;
            display: inline-block;
            margin: 28% 0 0 0;
            // &:hover,&.active{
            //     filter: sepia(100%);
            // }
        }

        @media (min-width: 1440px) {
            .logo-icon {
                width: 40%;
                height: auto;
            }
        }

        ul {
            // padding: 30px 0;
            padding: 60% 0;
            margin: 0;

            li {
                padding: 10% 0;

                a,
                button {
                    padding: 25% 0;
                    // cursor: pointer;
                    width: 100%;
                    height: 3.5em;
                    // height: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    background-color: transparent;

                    img {
                        width: 32%;
                        height: auto;
                    }

                    &:hover,
                    &.active {
                        background-color: #233960;
                        // background: url('../../../assets/icons/dashboard-2-line.svg');
                        // background-size: auto 38%;
                        // background-repeat: no-repeat;
                        // background-position: center;
                        // background: url('../../../assets/img/dashboard-active.png');
                    }

                    transition: all 0.3s ease-in-out;

                    // border: 1px solid white;
                    // &:hover,&.active{
                    //     filter: sepia(100%);
                    // }
                    // @media (min-width: 1440px) {
                    //     width: 32px !important;
                    //     height: 32px !important;
                    // }
                    // &:hover, &.active {
                    //     background-color: #233960;
                    //     background-size: auto 38%;
                    //     background-repeat: no-repeat;
                    //     background-position: center;
                    // }
                }

            }

            // .li-1 {
            //     button {
            //         // background: url('../../../assets/icons/dashboard-2-line.svg');
            //         // background-size: auto 38%;
            //         // background-repeat: no-repeat;
            //         // background-position: center;

            //         &:hover,
            //         &.active {
            //             background-color: #233960;
            //             // background: url('../../../assets/icons/dashboard-2-line.svg');
            //             // background-size: auto 38%;
            //             // background-repeat: no-repeat;
            //             // background-position: center;
            //             // background: url('../../../assets/img/dashboard-active.png');
            //         }

            //         transition: all 0.3s ease-in-out;
            //     }
            // }

            // .li-2 {
            //     button {
            //         // background: url('../../../assets/icons/inbox-line.svg');
            //         // background-size: auto 38%;
            //         // background-repeat: no-repeat;
            //         // background-position: center;

            //         &:hover,
            //         &.active {
            //             background-color: #233960;
            //             // background-image: url('../../../assets/icons/inbox-line.svg');
            //             // background-size: auto 38%;
            //             // background-repeat: no-repeat;
            //             // background-position: center;
            //             // background: url('../../../assets/img/inbox-active.png');
            //         }

            //         transition: all 0.3s ease-in-out;
            //     }
            // }

            // .li-3 {

            //     a,
            //     button {
            //         // background: url('../../../assets/icons/feedback-line.svg');
            //         // background-size: auto 38%;
            //         // background-repeat: no-repeat;
            //         // background-position: center;

            //         &:hover,
            //         &.active {
            //             background-color: #233960;
            //             // background: url('../../../assets/img/feedback-active.png');
            //             // background: url('../../../assets/icons/feedback-line.svg');
            //             // background-size: auto 38%;
            //             // background-repeat: no-repeat;
            //             // background-position: center;
            //         }

            //         transition: all 0.3s ease-in-out;
            //     }
            // }

            // .li-4 {

            //     a,
            //     button {
            //         // background: url('../../../assets/icons/video-line-icon.svg');
            //         // background-size: auto 38%;
            //         // background-repeat: no-repeat;
            //         // background-position: center;

            //         // background-size: auto 38%;

            //         &:hover,
            //         &.active {
            //             background-color: #233960;
            //             // background: url('../../../assets/img/video-testimonial-active.png');
            //             // background: url('../../../assets/icons/video-line-icon.svg');
            //             // background-size: auto 38%;
            //             // background-repeat: no-repeat;
            //             // background-position: center;
            //         }

            //         transition: all 0.3s ease-in-out;
            //     }
            // }

            // .li-5 {

            //     a,
            //     button {
            //         // background: url('../../../assets/icons/google-line-icon.svg');
            //         // background-size: auto 38%;
            //         // background-repeat: no-repeat;
            //         // background-position: center;

            //         &:hover,
            //         &.active {
            //             background-color: #233960;
            //             // background: url('../../../assets/img/google-active.png');
            //             // background: url('../../../assets/icons/google-line-icon.svg');
            //             // background-size: auto 38%;
            //             // background-repeat: no-repeat;
            //             // background-position: center;
            //         }

            //         transition: all 0.3s ease-in-out;
            //     }
            // }

            // .li-6 {
            //     button {
            //         // background: url('../../../assets/icons/simple-facebook.svg') no-repeat;
            //         // background-size: auto 38%;
            //         // background-repeat: no-repeat;
            //         // background-position: center;

            //         &:hover,
            //         &.active {
            //             background-color: #233960;
            //             // background: url('../../../assets/img/facebook-active.png') no-repeat;
            //             // background: url('../../../assets/icons/simple-facebook.svg') no-repeat;
            //             // background: url('../../../assets/icons/simple-facebook.svg') no-repeat;
            //             // background-size: auto 38%;
            //             // background-repeat: no-repeat;
            //             // background-position: center;
            //         }

            //         transition: all 0.3s ease-in-out;
            //     }

            // }

            // .li-7 {

            //     a,
            //     button {
            //         // background: url('../../../assets/img/twitter-normal.svg');
            //         // background-size: auto 38%;
            //         // background-repeat: no-repeat;
            //         // background-position: center;

            //         &:hover,
            //         &.active {
            //             background-color: #233960;
            //             // background: url('../../../assets/img/twitter-active.png');
            //             // background: url('../../../assets/img/twitter-normal.svg');
            //             // background-size: auto 38%;
            //             // background-repeat: no-repeat;
            //             // background-position: center;
            //         }

            //         transition: all 0.3s ease-in-out;
            //     }
            // }

        }
    }
}