.mobile-calendar {
    display: none;

    @media(max-width: 769px) {
        display: block;
    }
}

.calendar-lg {
    display: block;

    @media(max-width: 769px) {
        display: none;
    }
}

.search-wrapper {
    position: relative;
    background-color: #FFFFFF;
    z-index: 10;
    font-family: 'Graphik';
    font-style: normal;

    .bot-search-text {
        position: absolute;
        right: 40px;
        top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 769px) {
            right: 74px;
            top: 10px;
        }

        max-width: 170px;
        // padding: 0 15px;
        text-align: left;

        i {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
            color: #8999AB;
            font-weight: lighter;
            font-size: 10px;
        }

        p {
            font-size: 10px;
            padding: 4px 5px;
        }
    }

    .search-text {
        position: absolute;
        right: 60px;
        top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 769px) {
            right: 95px;
            top: 10px;
        }

        max-width: 170px;
        // padding: 0 15px;
        text-align: left;

        i {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
            color: #8999AB;
            font-weight: lighter;
            font-size: 10px;
        }

        p {
            font-size: 10px;
            padding: 4px 5px;
        }
    }

    .mobile-calender-filter {
        @media(max-width: 769px) {
            display: none;
        }
    }

    .search-icon,
    .mobile-nav-bar {
        position: absolute;
        left: 12px;
        top: 10px;
        height: 15px;
        width: 15px;
        cursor: pointer;
    }

    .mobile-nav-bar {
        display: none;

        @media(max-width: 769px) {
            display: block;
            top: 15px;
        }
    }

    @media(max-width: 769px) {
        .search-icon {
            display: none;
        }
    }


    .filter {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 10px;
        /* identical to box height, or 100% */

        letter-spacing: 0.01em;
    }

    input {
        width: 100%;

        @media (max-width: 769px) {
            width: 100% !important;
        }

        @media(max-width: 769px) {
            height: 44px;

        }

        // @media (min-width: 1470px) {
        //     width: 100% !important;
        // }
        height: 35px;
        background: #FFFFFF;
        border: 1.3px solid #D3E0FF;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 0 45px 0 30px;

        @media(max-width: 769px) {
            padding: 0 45px 0 35px;
        }

        outline: none;
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 100% */

        letter-spacing: 0.01em;

        /* Text/Placeholder */

        color: #98A2B3;
    }

    input::placeholder {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 400;
        color: #667085;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.02em;
    }

    .refresh-icon {
        position: absolute;
        right: 5px;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-top: 5px;

        @media(max-width: 769px) {

            // right: 12%;
            img {
                margin-top: 5px;
            }
        }
    }

    .add-options {
        display: none;
        height: 90%;

        @media(max-width: 769px) {
            display: block;
            margin: auto 0 auto 8px;
        }
    }

    .ws-logo {
        // position: absolute;
        // right: -18px;
        // top: 4px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        cursor: pointer;
        display: none;

        @media(max-width: 769px) {
            display: block;
        }
    }
}