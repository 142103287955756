.star-ratings-css {
  unicode-bidi: bidi-override;
  color: #CBCBCB;
  width: 65px;
  margin: 0;
  position: relative;
  padding: 0;

  &-top {
    color: #FFCA00;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
    font-size: 16px;
  }

  &-bottom {
    font-size: 16px;
    padding: 0;
    display: block;
    z-index: 0;
  }
}