.ant-modal-header {
    padding: 0 1% !important;
}

.ant-modal-body {
    padding: 0 !important;
}

.cross-icon-container {

    .cross-icon-btn {
        cursor: pointer;
        background: #ABB9CC;
        border-radius: 50%;
        height: auto;
        width: fit-content;
        padding: 1% 1.05% 1% 1%;

        .cross-icon {
            display: block;
            color: #fff;
            height: auto;
            width: 0.5vw;
        }
    }
}

.feed-scrolling {
    height: 80vh !important;

    @media (max-height: 620px) {
        height: 72vh !important;
    }

}

.feed-card-header-conversation {
    padding: 2% 4.5% 4% 3% !important;
    // padding: 2% 4.5% 1% 3%;
}

.wall-box-modal {
    // padding: 0 5%;

    .feeds-card-header {
        cursor: pointer;
        padding: 0 4.5% 3% 3%;
        gap: 1%;

        .feeds-card-header-content {
            margin-left: 0.25rem;

            .provider-icon {
                width: 1.5vw;
                height: auto;

                @media(max-width: 769px) {
                    width: 5vw;
                }
            }
        }

        .user-avatar {
            // height: 1.8rem;
            // width: 2rem;
            height: 32px;
            // width: auto;
            width: auto;
            border-radius: 50%;
            // padding: 1%;
        }

        .user-name-avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            width: 32px;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
            background: #14B0BF;
        }

        .rating-value {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 0.875rem;
            color: #17173A;

            @media(max-width: 1400px) {
                font-size: 0.6875rem;
                // line-height: 0.6875rem;
            }
        }

        .recommend-not-recommend-image {
            display: flex;
            // align-items: center;
            gap: 12%;

            .image-fb-title {
                // margin-top: 1%;
                display: flex;
                align-items: center;
                gap: 4%;
                font-size: 0.6875rem;
            }

            img {
                margin-top: 2%;
                width: 0.8vw;
                height: auto;

                @media(max-width: 769px) {
                    width: 2.5vw;
                }
            }
        }

        .user-name,
        .rating {
            font-weight: 600;
            font-size: 0.875rem;
            // line-height: 1rem;
            color: #17173A;
            // margin-right: 0.4rem;

            img {
                width: 0.7vw;
                height: auto;
                margin-bottom: 4%;
            }

            @media (max-width: 1400px) {
                // margin-top: 1;
                font-size: 0.75rem;
                font-weight: 600;
                line-height: 0.75rem;

                img {
                    width: 0.8vw;
                    height: auto;
                    margin-bottom: 0;

                    @media(max-width: 769px) {
                        width: 1.8vh;
                    }
                }
            }
        }

        .client-container,
        .client-name,
        .client {
            font-family: 'Graphik';
            font-style: italic;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 0.875rem;
            letter-spacing: 0.01em;
            color: #17173A;


            @media (max-width: 1400px) {
                font-size: 0.6875rem;
                font-weight: 400;
                line-height: 1.2rem;
                margin-right: 0.5rem;

                img {
                    width: 0.8vw;
                    height: 0.5vh;
                }
            }
        }

        .client {
            color: #667085;
        }

        .client-name {
            font-style: normal;
            font-weight: 700;

        }

        .date {

            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
            color: #667085;
            margin-top: 2%;

            @media (max-width: 1400px) {
                font-size: 0.6875rem;
                line-height: 1rem;
            }

            a {
                font-weight: 400;
                font-size: 0.65rem;
                line-height: 1rem;
                color: #667085;

            }

            // @media (max-width: 1400px) {
            //     margin-top: px;
            // }
        }

        .description {
            margin-top: 5%;
            font-weight: 400;
            font-size: 0.9875rem;
            line-height: 1.5rem !important;
            // letter-spacing: 0.02rem;
            text-align: justify;
            color: #475467;
            min-height: 10vh;

            @media(max-width: 1400px) {
                line-height: 0.75rem;
                font-size: 0.75rem;
                // letter-spacing: 0.01em;
            }
        }
    }

    .feeds-card-footer {
        padding: 2% 4.5% 1% 3%;
        border-top: 1.5px solid #D3E0FF;

        .prev-next-add-wall-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .prev-next-container {
                font-family: "Graphik";
                font-style: normal;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                // gap: 40%;
                // padding: 1% 1%;

                background: #E4E7EC;
                border-radius: 4px;

                padding: 2% 0%;

                width: fit-content;
                font-size: 0.5rem;

                .prev-next {
                    width: 2.8vw;
                    height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // gap: 40%;
                    margin: auto;

                    // border: 1px solid blue;

                    .prev,
                    .next {
                        width: 20%;
                        height: auto;
                        margin: auto;
                        // border: 1px solid blue;
                    }
                }

                // @media(max-width: 1400px) {

                // }
            }

            .add-to-wall-btn {
                // display: block;
                font-family: "Graphik";
                font-style: normal;
                padding: 1% 3.4% 1% 3.4%;
                // width: 5.8vw;

                @media(max-width: 769px) {
                    width: 20.8vw;
                }

                background: #E4E7EC;
                border-radius: 4px;
                font-weight: 500;
                font-size: 0.7rem;
                // line-height: 0.625rem;
                letter-spacing: 0.01em;
                color: #17173A;
                transition: all 0.3s ease-in-out;

                @media(max-width: 1400px) {
                    width: auto;
                    font-size: 0.5rem;
                    padding: 2% 3%;

                }
            }
        }

    }
}

.request-review-form {
    padding: 0 8%;

    @media(max-width: 769px) {
        .request-review-title {
            h4 {
                font-size: 18px;
            }

            p {
                font-size: 13px;
            }
        }
    }

}

.feeds-row-mobile,
.feeds-conversations-details {
    display: block;

    // padding: 1% 4% 1% 2%;
    // padding: 2.1% 4% 0% 2%;
    // padding: 0 10px;
    @media (min-width: 769px) {
        display: none !important;
        width: 100% !important;
    }

    .mobile-feeds {
        height: 90vh;
    }

    // .mobile-conversations {
    //     height: 1000vh;
    // }
}

.feeds-row-lg {
    display: block;

    @media (max-width: 769px) {
        display: none;
    }

}

.ant-tooltip-inner {
    color: #000 !important;
}

.lg-description {
    display: none;

    @media(min-width: 1601px) {
        display: block;
    }
}

.normal-description {
    display: none;

    @media(max-width: 1600px) {
        display: block;
    }
}


.feed-card-active {
    // background: #EEF4FF;
    background: #EEF4FF;
}

.feed-card-hover {
    &:hover {
        background: #EEF4FF;
    }
}


.feeds-container {
    padding: 0 1% 0 0.4%;

    // height: 100vh;
    // overflow-y: auto;
    ::-webkit-scrollbar-thumb {
        background: transparent !important;
        height: 0 !important;
    }

    .conversation-wall-mobile {
        display: none;
        border-top: 1px solid #1570EF;
        border-bottom: 1px solid #1570EF;
        height: 70px;
        // height: 70px;
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
        width: 100%;

        @media (max-width: 769px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
        }

        .coder-wall-btn,
        .gallery-btn,
        .inbox-btn {
            border: 2px solid #1570EF;
        }

        .inbox-btn,
        .inbox-btn-active {
            box-sizing: border-box;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: url('../../assets/icons/material-message-active.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            transition: all 0.3s ease-in-out;
        }

        .inbox-btn:hover,
        .inbox-btn-active {
            background-color: #1570EF;
            background-image: url('../../assets/icons/material-message.svg');
        }

        .gallery-btn,
        .gallery-btn-active {
            box-sizing: border-box;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            // background: url('../../assets/icons/gallery-icon-active.svg');
            background: url('../../assets/icons/gallery-icon-active.svg');
            background-repeat: no-repeat;
            background-position: center;
            // background-color: #1570EF;
            background-size: 50%;
            transition: all 0.3s ease-in-out;
        }

        .gallery-btn:hover,
        .gallery-btn-active {
            background-color: #1570EF;
            background-image: url('../../assets/icons/gallery-icon.svg');
        }

        .coder-wall-btn,
        .coder-wall-btn-active {
            box-sizing: border-box;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: url('../../assets/icons/simple-coderwall-active.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            transition: all 0.3s ease-in-out;
        }

        .coder-wall-btn:hover,
        .coder-wall-btn-active {
            background-color: #1570EF;
            background-image: url('../../assets/icons/simple-coderwall.svg');
        }

        // Text of buttons
        .mobile-inbox-gallery-wall {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.01em;
            color: #17173A;
        }


        .inbox-gallery-wall-active {
            color: #1570EF;
        }
    }

    .conversation-wall {
        font-family: "Graphik";
        z-index: 998;
        position: fixed;
        top: 2.5%;
        left: 40%;
        display: flex;
        gap: 10%;

        button {
            border: none;
            background: transparent;
            outline: none;
            font-size: 0.85rem;
            font-weight: 500;
            line-height: 0.85rem;
            border-bottom: 2px solid white;
            cursor: pointer;
            padding: 3.8% 3%;
        }

        .inbox-button-active {
            font-weight: 600;
            border-bottom: 2px solid #1570EF;
            transition: all 0.2s ease-in-out;
        }

        @media (max-width: 769px) {
            display: none !important;
        }
    }

    // old version
    // .conversation-wall {
    //     position: fixed;
    //     gap: 10px;
    //     top: 9%;
    //     right: 15px;

    //     @media (max-width: 769px) {
    //         display: none !important;
    //     }
    // }

    .feeds-section {
        height: 100%;
        background: #FFF;
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-right: 3px;

        .feeds-section-hover {
            &:hover {
                ::-webkit-scrollbar-thumb {
                    // -webkit-transition: background-color 0.5s ease-in-out !important;
                    // -moz-transition: background-color 0.5s ease-in-out !important;
                    // -o-transition: background-color 0.5s ease-in-out !important;
                    // transition: background-color 0.5s ease-in-out !important;
                    background: #ddd !important;
                }
            }

            ::-webkit-scrollbar {
                // display: none;
                // -webkit-transition: background-color 1s ease-in-out !important;
                // transition: background-color 1s ease-in-out !important;
                width: 5px;
                cursor: pointer;
            }

            ::-webkit-scrollbar-track {
                height: 5px !important;
                box-shadow: inset 0 0 5px #fff;
                background-color: #fff !important;
                margin: 0 !important;
                // background-color: transparent ;
                // border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb {
                background-color: transparent !important;
                border-radius: 10px !important;
            }
        }

        .feeds-card {
            font-family: "Graphik";
            font-style: normal;
            border: 1px solid #D3E0FF;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
            letter-spacing: 0.01em;
            margin: 1.8% 0;
            // &:hover {
            // background: red;
            // background: #EEF4FF;
            // background: #EEF4FF;
            // }

            .feeds-card-header {
                padding: 3% 3.5% 0 3%;
                gap: 1%;

                .feeds-card-header-content {
                    margin-left: 0.25rem;

                    .provider-icon {
                        // width: 1vw;
                        width: 1.3vw;
                        height: auto;

                        @media(max-width: 769px) {
                            width: 5vw;
                        }
                    }
                }

                .user-avatar {
                    // height: 1.8rem;
                    // width: 2rem;
                    height: 32px;
                    // width: auto;
                    width: auto;
                    border-radius: 50%;
                    // padding: 1%;
                }

                .user-name-avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 28px;
                    width: 32px;
                    border-radius: 50%;
                    font-size: 10px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 0;
                    padding: 0;
                    margin: 0;
                    background: #14B0BF;
                }

                .rating-value {
                    font-family: "Graphik";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    color: #17173A;

                    @media(max-width: 1400px) {
                        font-size: 0.6875rem;
                        // line-height: 0.6875rem;
                    }
                }

                .recommend-not-recommend-image {
                    display: flex;
                    // align-items: center;
                    gap: 12%;

                    .image-fb-title {
                        // margin-top: 1%;
                        display: flex;
                        align-items: center;
                        gap: 4%;
                        font-size: 0.6875rem;
                    }

                    img {
                        margin-top: 2%;
                        width: 0.8vw;
                        height: auto;

                        @media(max-width: 769px) {
                            width: 2.5vw;
                        }
                    }
                }

                .user-name {
                    text-transform: capitalize;
                }

                .user-name,
                .rating {
                    font-weight: 600;
                    font-size: 0.875rem;
                    // line-height: 1rem;
                    color: #17173A;
                    // margin-right: 0.4rem;

                    img {
                        width: 0.7vw;
                        height: auto;
                        margin-bottom: 4%;
                    }

                    @media (max-width: 1400px) {
                        // margin-top: 1;
                        font-size: 0.75rem;
                        font-weight: 600;
                        line-height: 0.75rem;

                        img {
                            width: 0.8vw;
                            height: auto;
                            margin-bottom: 0;

                            @media(max-width: 769px) {
                                width: 1.8vh;
                            }
                        }
                    }
                }

                .client-container,
                .client-name,
                .client {
                    font-family: 'Graphik';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    letter-spacing: 0.01em;
                    color: #17173A;


                    @media (max-width: 1400px) {
                        font-size: 0.6875rem;
                        font-weight: 400;
                        line-height: 1.2rem;
                        margin-right: 0.5rem;

                        img {
                            width: 0.8vw;
                            height: 0.5vh;
                        }
                    }
                }

                .client {
                    color: #667085;
                }

                .client-name {
                    font-style: normal;
                    font-weight: 700;

                }

                .date {

                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    color: #667085;
                    margin-top: 2%;

                    @media (max-width: 1400px) {
                        font-size: 0.6875rem;
                        line-height: 1rem;
                    }

                    a {
                        font-weight: 400;
                        font-size: 0.65rem;
                        line-height: 1rem;
                        color: #667085;

                    }

                    // @media (max-width: 1400px) {
                    //     margin-top: px;
                    // }
                }

                .description {
                    margin-top: 5%;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    // letter-spacing: 0.02em;
                    color: #475467;

                    @media(max-width: 1400px) {
                        line-height: 0.75rem;
                        font-size: 0.75rem;
                        // letter-spacing: 0.01em;
                    }
                }
            }

            .feeds-card-footer {

                .footer-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .left-emoji,
                    .left-body-emoji {
                        display: flex;
                        // justify-content: center;
                        align-items: center;
                        width: 100%;
                        gap: 5%;
                        color: #1849A9;
                        font-size: 0.6875rem;
                    }

                    .left-body-emoji {
                        gap: 3%;

                        .client {
                            font-weight: 400;
                            font-size: 0.6875rem;
                            line-height: 0.6875rem;
                            // color: #667085;

                        }
                    }
                }

                padding: 1.38% 3% 1.38% 11.9%;

                @media(max-width: 1601px) {
                    padding: 1.38% 3% 1.38% 14.2%;
                }

                border-top: 1.5px solid #D3E0FF;


                .like-container {
                    min-width: 50%;
                    display: flex;
                    align-items: center;
                    gap: 8%;

                    img {
                        width: 1vw;
                        height: 2vh;
                    }
                }

                .likes {
                    font-weight: 500;
                    font-size: 0.6875rem;
                    line-height: 0.680em;
                    color: #667085;
                }

                .add-to-wall-btn-hover {
                    &:hover {
                        background: #F4F3FF;
                        // border: 1px solid #18e4e4;
                        border-radius: 13px;
                    }
                }

                .add-to-wall-btn {
                    font-family: "Graphik";
                    font-style: normal;
                    padding: 1% 3.4% 0.9% 3.4%;
                    // width: 5.8vw;

                    @media(max-width: 769px) {
                        width: 20.8vw;
                    }

                    // height: 2.7vh;
                    background: #f8fbff;
                    border-radius: 13px;
                    font-weight: 500;
                    font-size: 0.7rem;
                    // line-height: 0.625rem;
                    letter-spacing: 0.01em;
                    color: #17173A;
                    transition: all 0.3s ease-in-out;
                    border: 1px solid #1570ef;

                    @media(max-width: 1400px) {
                        // width: 4.5vw;
                        width: auto;
                        font-size: 0.5rem;
                        padding: 1% 3.4% 0.9% 3.4%;

                    }

                    // &:hover {
                    //     background: #EEF4FF;
                    //     /* Button / Active Button Outline */

                    //     border: 1px solid #175CD3;
                    //     border-radius: 13px;
                    // }
                }
            }

            .treads {
                border-top: 1px solid #D3E0FF;

                .tread-container {
                    border-bottom: 1px solid #D3E0FF;
                }
            }
        }

        .unified-btn {
            padding-right: 2%;

            @media (max-width: 769px) {
                padding-right: 1%;
            }

            .active {
                background: #EEF4FF;
                /* Button / Active Button Outline */

                border: 1px solid #175CD3;
                border-radius: 13px;
            }
        }

        .unified-btn button {
            font-family: 'Graphik';
            font-style: normal;
            font-weight: 400;

            background: #D8E3FF;
            border-radius: 13px;
            box-sizing: border-box;
            outline: none;

            letter-spacing: 0.01em;

            color: #17173A;
            transition: all 0.3s ease-in-out;
            border: 1px solid #D3E0FF;

            &:hover {
                border: 1px solid #175CD3;
            }

            height: 2.5vh;
            font-size: 0.6875rem;
            line-height: 0.6875rem;
            // line-height: 1.6%;
            padding: 0 4%;

            @media (max-width: 1600px) {
                padding: 0 3.5%;
                font-size: 0.65rem;

                @media (max-width: 1400px) {
                    padding: 0 3%;
                    height: 3.5vh;
                    // height: 2.8vh;
                    // height: 18px;

                    @media (max-width: 769px) {
                        // height: 20px;
                        padding: 0 3%;
                        font-size: 0.6875rem;
                        // line-height: 1.6%;

                    }
                }
            }
        }


        .empty-feeds-section {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: auto;

            h4 {
                font-family: 'Graphik';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 15px;
                text-align: center;
                text-transform: capitalize;
                color: #2F407C;
                padding: 16px 0;
            }
        }

        .facebook-feeds {
            overflow-y: auto;
            height: calc(100vh - 200px);
            padding: 0 2px;

            .facebook-feeds-wrapper {
                box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
            }
        }

    }

    .wall-section {
        height: 100%;
        // background: red;
        background: #F4F3FF;
        // background: #EEF4FF;
        // background: #F5FAFF;
        border-radius: 5px 5px 0px 0px;
        // min-height: 60vh;
        display: flex;
        flex-direction: column;
        // padding: 0.5% 0 0 0;
        // padding: 10px 0 0 0;
        // padding: 10px 0 0 0 !important;

        padding: 0 0 0 0;
        position: relative;
        margin-top: 0.945%;
        // margin-top: 0.75rem;

        @media(max-width: 769px) {
            height: 81vh;
            margin-top: 0;
        }

        .wall-search-section {
            // height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .embed-wall {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;

                @media(max-width: 1025px) {
                    flex-direction: column;
                    gap: 10%;
                }
            }

            .search-wrap {
                position: relative;
                z-index: 1;
                // width: 500px;
                width: 50%;

                @media(max-width: 1025px) {
                    width: 90%;

                    @media(max-width: 769px) {
                        display: none;
                    }
                }

                .search-icon {
                    position: absolute;
                    left: 1.5%;
                    top: 32%;
                    height: auto;
                    width: 1vw;
                    cursor: pointer;

                    // @media(max-width: 1600px) {
                    //     left: 30.5%;

                    //     @media(max-width: 1400px) {
                    //         left: 31%;
                    //     }
                    // }
                }

                input {
                    width: 100% !important;
                    height: 5vh;
                    background: #FFFFFF;
                    border: 1.3px solid #D3E0FF;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 0.5% 0 0 5%;
                    outline: none;
                    font-family: 'Graphik';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    /* identical to box height, or 100% */

                    letter-spacing: 0.01em;

                    color: #98A2B3;
                }

                input::placeholder {
                    font-family: 'Graphik';
                    font-style: normal;
                    font-weight: 400;
                    color: #98A2B3;
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    letter-spacing: 0.02em;
                }
            }

            // .secondary {
            //     margin-left: 10px;
            //     height: 35px;
            // }
        }

        .empty-wall-section {
            display: flex;
            align-items: center;
            flex-direction: column;
            transform: translate(-50%, -50%);
            position: absolute;
            left: 50%;
            top: 50%;
            height: 185px;

            @media (max-width: 769px) {
                display: none;
            }

            h4 {
                font-family: 'Graphik';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 15px;
                text-transform: capitalize;
                color: #2F407C;
                padding: 16px 0;
            }

            span {
                font-family: 'Graphik';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 15px;
                text-transform: capitalize;
                color: #6476B5;
            }
        }
    }

    .conversation-right-side {
        height: 100%;
        margin-top: 0.945%;
        padding: 0 0.4%;

        .whats-app-details-container {
            padding-bottom: 10%;
            overflow: auto;
        }

        .bot-details-container,
        .whats-app-details-container {
            height: 100%;
            background: #F4F3FF;
            border-radius: 5px 5px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            // text-transform: capitalize;
        }

        .inbox-wall,
        .properties-wall,
        .details-wall {
            margin: 0 0 1.8% 0;

            .right-feed-card {
                // width: 330px;
                background: #F4F3FF;
                // background: #F5FAFF;
                border-radius: 5px;
            }
        }

        .inbox-wall {
            height: 20%;

            .right-feed-card {
                height: 100% !important;
            }
        }

        .details-wall,
        .properties-wall {
            height: 28%;

            .right-feed-card {
                height: 100%;
            }
        }

    }

    .embed-modal {
        display: flex;
        justify-content: space-between;

        input {
            width: 80%;
        }
    }
}

.ant-modal-content {
    padding: 0 0 10px 0 !important;
}