.profile-menu-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;

    .form-container,
    .profile-image {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5%;

        .form-group {
            font-family: 'Graphik';
            font-style: normal;
            position: relative;
            height: 17% !important;
            color: #17173A;
            // border: 1px solid red;

            // justify-content: center;

            label {
                margin-bottom: 1%;
            }

            label,
            span {
                font-weight: 400;
                font-size: 0.8rem;

                @media(max-width: 1400px) {
                    font-size: 0.6rem;

                    @media(max-width: 769px) {
                        font-size: 0.8rem;
                    }
                }

                color: #667085;
            }

            .profile-avatar,
            .upload-image-text {
                height: 19vh;
                width: auto;
            }

            .upload-button {
                transition: all 0.8s ease-in-out;
                border: 2px solid #D3E0FF;

                .upload-image-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 2%;
                    text-align: center;
                    color: #98A2B3;
                    font-weight: 500;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;
                    }
                }

                &:hover {
                    background: #f8f6f6;
                }
            }

            .input-container {
                position: relative;
                margin-top: 1%;
                height: 70%;
                // border-left: 4px solid #1570EF;
                border-left: none;
                // box-shadow: 0px 0px 5px rgba(23, 23, 58, 0.15);
                box-shadow: none;
                // border-radius: 0px 3px 3px 0px;
                border-radius: 5px;
                border: 1px solid var(--border-inbox, #D3E0FF);
                padding: 2% 2% 2% 5%;
                display: flex;
                align-items: center;

                input,
                select,
                .image-label {
                    // height: 50px;
                    width: 75%;
                    height: 50%;
                    margin: auto 0;
                    border: none;
                    outline: none;
                    font-weight: 400;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;
                    }

                    &:focus {
                        transition: all 0.3s ease-out;
                        border-bottom: 1px solid #A4BCFD;
                    }
                }

                input::placeholder {
                    font-weight: 400;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;
                    }

                    line-height: 0.9rem;
                    color: #667085;
                }

                .border-none {
                    border: none !important;

                    &:focus {
                        border: none !important;
                    }
                }

                .input-error {
                    border-bottom: 1px solid #fd1818 !important;
                }

                .add-edit-save-icon {
                    position: absolute;
                    right: 5%;
                    top: 35%;
                    height: auto;
                    width: auto;
                    // width: 4.5%;
                    cursor: pointer;
                }

                .disabled {
                    cursor: not-allowed;
                }

                .add-edit-save-btn {
                    position: absolute;
                    right: 2%;
                    top: 30%;
                    height: 50%;
                    // width: 20%;
                    padding: 2% 8%;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;
                    }

                    // line-height: 12px;
                    color: #17173A;
                    background: #E4E7EC;
                    border-radius: 25px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    transition: all 0.3s ease-out;

                    &:hover {
                        background: #c9c9ca;
                    }
                }
            }


            .image-label {
                // height: 50px;
                outline: none;
            }

            position: relative;

            .delete {
                position: absolute;
                top: 0;
                // right: 100*0.07353%;
                right: 0;
                cursor: pointer;
                z-index: 1;
                height: auto;
                width: auto;
                // width: 10%;
                // width: 8.53px;
                // height: 8.52px;
                // color: #8d8c8c;
                transition: all 0.5s ease-in-out;

                &:hover {
                    color: #ff0000;
                }
            }


            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                box-shadow: 2px 2px 12px 0px #0000001a;
                width: 100%;
                height: 50px;
                padding: 8px 11px;
            }

            .host-guest-container {
                margin-top: 2%;
                display: flex;

                .host-button,
                .guest-button {
                    padding: 4% 8%;
                    background: #FFFFFF;
                    box-shadow: 0.8px 0.8px 10px rgba(23, 23, 58, 0.1);
                    border-radius: 0;
                    font-weight: 400;
                    font-size: 0.9rem;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;
                    }

                    line-height: 0.9em;
                    letter-spacing: 0.01em;
                    color: #17173A;
                    transition: all 0.5s ease-out;

                    // &:hover {
                    //     // background: #D8E3FF;
                    // }
                }

                .button-active {
                    background: #D8E3FF;

                }
            }
        }
    }

    .profile-image {
        width: 30%;
    }
}

;