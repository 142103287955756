.lg-navigation {
    display: block !important;

    // @media (max-width: 769px) {
    @media (max-width: 576px) {
        display: none !important;
    }
}

.disabled {
    cursor: not-allowed !important;
}

// top tabs
.top-tab-wall {
    font-family: "Graphik";
    z-index: 99999;
    position: fixed;
    // top: 2.5%;
    // left: 40%;
    left: 42%;
    top: 2.2%;

    @media(max-width: 1200px) {
        left: 38%;

        @media(max-width: 1024px) {
            left: 35%;
            top: 1.5%;
        }
    }

    // left: 35%;
    display: flex;
    gap: 10%;

    button {
        border: none;
        background: transparent;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 0.85rem;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        padding: 3% 3%;
    }

    .inbox-button-active {
        font-weight: 600;
        border-bottom: 2px solid #1570EF;
        transition: all 0.2s ease-in-out;
    }

    // @media (max-width: 576px) {
    @media (max-width: 769px) {
        display: none;
    }
}

// mobile-bottom-tabs
.mobile-bottom-tabs {
    display: none;
    border-top: 1px solid #1570EF;
    border-bottom: 1px solid #1570EF;
    height: 70px;
    // height: 70px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;

    // @media (max-width: 769px) {
    @media (max-width: 769px) {
        display: flex;
        // justify-content: space-around;
        justify-content: center;
        align-items: center;
        gap: 120px;
        padding: 0 20px;
        width: calc(100% - 70px);
        left: 70px;
        z-index: 9999;

        @media(max-width: 576px) {
            gap: 100px;
            width: 100%;
            left: 0;
        }
    }

    .coder-wall-btn,
    .gallery-btn,
    .inbox-btn {
        border: 2px solid #1570EF;
    }

    .inbox-btn,
    .inbox-btn-active {
        box-sizing: border-box;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: url('../../assets/icons/material-message-active.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        transition: all 0.3s ease-in-out;
    }

    .inbox-btn:hover,
    .inbox-btn-active {
        background-color: #1570EF;
        background-image: url('../../assets/icons/material-message.svg');
    }

    .gallery-btn,
    .gallery-btn-active {
        box-sizing: border-box;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        // background: url('../../assets/icons/gallery-icon-active.svg');
        background: url('../../assets/icons/gallery-icon-active.svg');
        background-repeat: no-repeat;
        background-position: center;
        // background-color: #1570EF;
        background-size: 50%;
        transition: all 0.3s ease-in-out;
    }

    .gallery-btn:hover,
    .gallery-btn-active {
        background-color: #1570EF;
        background-image: url('../../assets/icons/gallery-icon.svg');
    }

    .coder-wall-btn,
    .coder-wall-btn-active {
        box-sizing: border-box;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: url('../../assets/icons/simple-coderwall-active.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        transition: all 0.3s ease-in-out;
    }

    .coder-wall-btn:hover,
    .coder-wall-btn-active {
        background-color: #1570EF;
        background-image: url('../../assets/icons/simple-coderwall.svg');
    }

    // Text of buttons
    .mobile-inbox-gallery-wall {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: #17173A;
    }


    .inbox-gallery-wall-active {
        color: #1570EF;
    }
}

.gallery-info-bottom-tabs {
    display: none;
    border-top: 1px solid #1570EF;
    // border-bottom: 1px solid #1570EF;
    height: 60px;
    // height: 70px;
    background: #FFFFFF;
    position: fixed;
    bottom: 70px;
    left: 0;
    z-index: 100;
    width: 100%;

    @media (max-width: 769px) {
        display: flex;
        gap: 100px;
        // justify-content: space-around;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
    }

    .preview-info,
    .preview-info-active {
        // height: 30px;
        padding: 5px;
        border-radius: 5px;

        img {
            width: 30px;
            height: auto;
        }
    }

    .preview-info-active {
        background: #EEF4FF;
    }

}

// ======  Messages - (Whatsapp, Bot) Tab - Start ======


// big device
.right-tab-messages {
    font-family: "Graphik";
    z-index: 99;
    position: fixed;
    top: 10%;
    right: 0.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: 1px solid #e91c1c;
    gap: 0.5rem;
    width: 2%;

    @media(max-width: 769px) {
        display: none;
    }

    button {
        // border: 2px solid blue;
        // border-radius: 50%;
        border-radius: 5px;
        background: transparent;
        outline: none;
        cursor: pointer;
        // height: 4.5vh;
        height: 3.8vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 18%;

        img {
            height: 100%;
            width: 100%;
        }

    }

    .btn-title {
        display: none;
    }

    .inbox-button-active {
        font-weight: 600;
        // border: 2px solid #1570EF;
        background: #D8E3FF;
        transition: all 0.2s ease-in-out;
    }

    // @media (max-width: 699px) {
    //     display: none !important;
    // }
}

// mobile
.bottom-tab-messages {
    display: none;
    border-top: 1px solid #1570EF;
    border-bottom: 1px solid #1570EF;
    height: 70px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    // width: 100vw;
    overflow: auto;

    @media (max-width: 769px) {
        display: flex;
    }

    font-family: "Graphik";
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // gap: 2rem;

    .btn-container {
        // border: 2px solid red;
        height: 100%;
        width: 50vw;
        float: left;
        // width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    button {
        border: 2px solid #1570EF;
        border-radius: 50%;
        background: transparent;
        outline: none;
        cursor: pointer;
        width: 20%;
        height: auto;
        // height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2.5%;

        // img {
        //     height: 100%;
        //     width: 100%;
        // }
    }

    .btn-title {
        display: none;
        font-size: 0.75rem;
        font-weight: 500;

        @media(max-width: 769px) {
            display: block;
        }
    }

    .inbox-button-active {
        font-weight: 600;
        // border: 2px solid #1570EF;
        background: #D8E3FF;
        transition: all 0.2s ease-in-out;
    }
}

// ======  Messages - (Whatsapp, Bot) Tab - End ======


.lg-md-device {
    display: block;

    @media (max-width: 1025px) {
        display: none !important;
    }
}

.md-sm-device {
    display: none !important;

    @media (max-width: 1024px) {
        display: block !important;
    }

    // .ant-switch-handle {
    //     top: 2px;
    //     left: 2px;
    //     width: 40%;
    //     height: 82%;
    // }
}

.lg-device {
    display: block;

    @media (max-width: 769px) {
        display: none !important;
    }
}

.sm-device {
    display: none !important;

    @media (max-width: 769px) {
        display: block !important;
    }

    // .ant-switch-handle {
    //     top: 2px;
    //     left: 2px;
    //     width: 40%;
    //     height: 82%;
    // }
}

.special-lg-md-device {
    @media (max-width: 576px) {
        display: none !important;
    }
}

.special-sm-device {
    display: none;

    @media (max-width: 576px) {
        display: block;
    }
}

.special-gen-sm-device {
    display: none;

    @media(max-width: 769px) {
        display: block;

        @media(max-width: 380px) {
            display: none;
        }
    }
}

.special-ex-sm-device {
    display: none;

    @media(max-width: 380px) {
        display: block;
    }
}

.header-title {
    font-family: "Graphik";
    font-size: 24px;
}

.nav-link {
    font-family: "Graphik";
    color: #2d1b1b !important;
}

.tab-item {
    // color: #11266d;
    font-family: "Graphik";
    color: #17173A;

    &:hover {
        // color: #11266d;
        color: #17173A;
    }
}

.layout-wrapper {
    font-family: "Graphik";
    display: flex;
    flex-direction: row;

    .main-body {
        height: 100vh;
        margin-left: 4.3%;

        @media(max-width: 768px) {
            margin-left: 70px;

            @media (max-width: 576px) {
                margin-left: 0;
            }
        }

        .lg-header {
            // height: 8%;
            height: 54px;
            display: block;

            @media(max-width: 769px) {
                display: none;
            }

        }

        .mobile-header {
            display: none;
            height: 8%;

            @media(max-width: 769px) {
                display: block;
            }
        }

        .special-mobile-header {
            display: none;
            height: 8%;

            @media(max-width: 769px) {
                display: block;

                @media(max-width: 576px) {
                    display: none;
                }
            }
        }
    }

    .dashboard-layout-sm {
        width: 100%;
        display: none;

        @media(max-width: 576px) {
            display: block;
        }

        height: 100vh;

        overflow: scroll;

        // @media(min-height: 900px) {
        //     height: 90.4vh;
        // }

        // @media(max-height: 769px) {
        //     height: 90.4vh;
        // }

        // background: #FBFCFF;
        &.padZero {
            padding: 0;
            background: inherit;
        }
    }

    .dashboard-layout-lg {
        width: 100%;
        display: block;

        @media(max-width: 576px) {
            display: none;
        }

        // height: 100vh;
        height: 100vh;

        overflow: hidden;

        @media(min-height: 900px) {
            // height: 91vh;
            height: 91.7vh;
        }

        @media(max-height: 769px) {
            // height: 90.2vh;
            // height: 100%;
            height: 91.7vh;
        }

        // background: #FBFCFF;
        &.padZero {
            padding: 0;
            background: inherit;
        }
    }

    .form-input-field {
        margin-top: 2px;
        padding: 5px 5px 5px 10px;
        // text-align: center;
        border-radius: 5px;
        border: 1px solid #e5e5e5;
        outline: none;
    }

    .form-input-field:focus {
        border: 3px solid #c3d3d4;
    }

    // new design style-15/07/23
    .main-container {
        font-family: 'Graphik';
        font-style: normal;
        text-align: start;
        background: #F4F3FF;

        @media(max-width: 769px) {
            background: #fff;
        }

        height: 92vh;

        .lg-sub-menu {
            font-family: 'Graphik';
            font-style: normal;
            height: 100%;
            padding: 3% 5%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5%;

            @media(max-width: 769px) {
                display: none !important;
            }

            .loading-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .mobile-sub-menu {
            font-family: 'Graphik';
            font-style: normal;
            display: none;

            .loading-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60vh;
            }

            @media(max-width: 769px) {
                display: block;
                padding: 0 !important;
            }

            .form-item {
                p {
                    font-weight: normal;
                    font-size: 0.9rem;
                    line-height: 0.9rem;
                    color: #000000;
                }

                input {
                    height: 6vh;
                    width: 100%;
                    font-size: 0.9rem;
                }
            }

            .convert-button {
                position: absolute;
                right: 8%;
                bottom: 25%;
                height: 3.2vh;
                background: #E4E7EC;
                border-radius: 10px;
                padding: 0 3%;
                font-weight: normal;
                font-size: 0.75rem;
                line-height: 0.75rem;
                color: #000000;
            }

            .save-change-button {
                padding: 0 5%;
                gap: 10px;
                height: 38px;
                font-size: 0.9rem;
                background: #D8E3FF;
                border-radius: 5px;
            }
        }

        .parent-tabs {
            height: 100%;
            background: #fff;
            padding: 2% 1%;
            border-radius: 10px;
            width: 24%;

            .logo-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5%;
                border-bottom: 1px solid var(--border-inbox, #D3E0FF);
                padding: 5% 8%;

                .workspace-logo {
                    height: auto;
                    width: 15%;
                    border-radius: 100%;
                }

                .workspace-name {
                    font-size: 0.9rem;
                    font-weight: 500;

                    @media(max-width: 1400px) {
                        font-size: 0.6875rem;
                        // font-size: 0.75rem;
                    }

                    color: #17173A;
                }

                margin-bottom: 5%;
            }

            .parent-tabs-container {
                // border: 1px solid red;
                height: 85%;
                display: flex;
                flex-direction: column;
                gap: 1%;
                padding: 0 6%;

                .parent-tab-link,
                .active {
                    padding: 0 0 0 8%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5%;

                    font-weight: normal;
                    color: #17173A;
                    border-radius: 5px;
                    height: 11%;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        background: var(--bg-active-text-bg, #D8E3FF);
                        color: var(--text-active, #1570EF);
                    }

                    img {
                        height: auto;
                        width: auto;
                        // width: 10%;
                    }

                    p {
                        font-size: 0.9rem;

                        @media(max-width: 1400px) {
                            font-size: 0.75rem;
                        }
                    }
                }

                .active {
                    background: var(--bg-active-text-bg, #D8E3FF);
                    color: var(--text-active, #1570EF);
                }
            }
        }

        .child-tabs {
            height: 90%;
            background: #fff;
            padding: 2% 2%;
            border-radius: 10px;
            width: 76%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2%;

            .child-tabs-container {
                border-right: 1px solid var(--border-inbox, #D3E0FF);
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 1%;
                padding: 0 2% 0 0;
                width: 25%;

                .child-tab-link,
                .active {
                    padding: 0 5%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5%;
                    font-weight: normal;
                    color: #17173A;
                    border-radius: 5px;
                    height: 10%;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        background: var(--bg-active-text-bg, #D8E3FF);
                        color: var(--text-active, #1570EF);
                    }

                    img {
                        height: auto;
                        width: auto;
                        // width: 8%;
                    }

                    p {
                        font-size: 0.9rem;

                        @media(max-width: 1400px) {
                            font-size: 0.75rem;
                        }
                    }
                }

                .active {
                    background: var(--bg-active-text-bg, #D8E3FF);
                    color: var(--text-active, #1570EF);
                }
            }



            .child-tab-components {
                height: 100%;
                width: 75%;
                overflow: hidden;

                .input-value {
                    color: var(--text-body, #101828);
                    font-size: 0.9rem;
                    font-weight: 400;
                    margin: auto 0;

                    @media(max-width: 1400px) {
                        font-size: 0.75rem;

                        @media(max-width: 769px) {
                            font-size: 0.9rem;
                        }
                    }
                }

                .scroll-section-hover {
                    overflow-y: auto;
                    height: 100%;
                    // background: #FFF;
                    display: flex;
                    flex-direction: column;
                    padding-top: 0;
                    // padding-right: 0.5%;
                    // padding-right: 1.5%;
                    padding-right: 2.5%;

                }

                transition: all 0.9s ease-in-out;

                // &:hover {
                //     ::-webkit-scrollbar-thumb {
                //         // -webkit-transition: background-color 0.5s ease-in-out !important;
                //         // -moz-transition: background-color 0.5s ease-in-out !important;
                //         // -o-transition: background-color 0.5s ease-in-out !important;
                //         // transition: background-color 0.5s ease-in-out !important;
                //         background: #ddd !important;
                //         height: 10px !important;
                //     }
                // }
                ::-webkit-scrollbar-thumb {
                    // -webkit-transition: background-color 0.5s ease-in-out !important;
                    // -moz-transition: background-color 0.5s ease-in-out !important;
                    // -o-transition: background-color 0.5s ease-in-out !important;
                    // transition: background-color 0.5s ease-in-out !important;
                    background: #ececec !important;
                    height: 10px !important;
                    border-radius: 4px;
                }

                ::-webkit-scrollbar {
                    // display: none;
                    // -webkit-transition: background-color 1s ease-in-out !important;
                    // transition: background-color 1s ease-in-out !important;
                    width: 6px;
                    height: 5px;
                    // background-color: red !important;
                    cursor: pointer;
                }

                ::-webkit-scrollbar-track {
                    height: 5px !important;
                    box-shadow: inset 0 0 5px #fff;
                    background-color: #fff !important;
                    margin: 0 !important;
                    // background-color: transparent ;
                    // border-radius: 10px;
                }

                // ::-webkit-scrollbar-thumb {
                //     background-color: transparent !important;
                //     border-radius: 10px !important;
                // }


            }
        }
    }
}


.sub-menu-col {
    flex-direction: column;
}

@media (max-width: 996px) {
    .sub-menu {
        // min-height: 5vh;
        margin: 5px 0;
    }
}

.sub-menu {
    height: 100vh;
}

.special-row>.col,
.special-row>.col-3,
.special-row>.col-12 {
    padding: 0 !important;
    text-align: start;
}

.nav-pills .nav-link.active {
    background: #f8f9fa !important;
    color: #3d74b2 !important;
    border-right: 2px solid #eee;
}


@media (max-width: 769px) {
    .nav-link {
        font-size: 12px !important;
    }

    .sub-menu-col {
        text-align: center;
    }
}

.ant-table table {
    font-size: 13px;
    // margin: 15px 0;
}

// thead[class*="ant-table-thead"] th{
// background: #fff;
// background-color: #182B72 !important;
// background: #17173A;
// background: linear-gradient( 180deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
// background: -moz-linear-gradient( 0deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
// background: -webkit-linear-gradient( 0deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
// background: -ms-linear-gradient( 0deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
// color: #fff !important;
// border: none;
// font-size: 14px;
// }

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
}

.ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important;
}

// .ant-table-thead > tr > th{
//     // background: #fff;
//     // vertical-align: auto !important;
// }

.common-btn {
    width: 10vw;
    height: 5vh;
    font-weight: 400;
    font-size: 0.9px;

    @media(max-width: 1400px) {
        font-size: 0.75px;

        @media(max-width: 769px) {
            width: 30vw;
            // height: 10vh;
            padding: 2% 0;
            font-size: 0.9px;
        }
    }

    color: #17173A;
    background: #D8E3FF !important;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // background: linear-gradient( 0deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%);
    // background-color: #e1e7ed !important;
    // // color: #;
    // // border-color: #172b70;
    // // background-color: #182B72;
    // // &:hover{
    // //     background: linear-gradient( 90deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%);
    // //     // background-color: #182B72;
    // // }
    // border-radius: 5px;

    &:hover {
        color: #17173A !important;
        // border-color: #172b70;
        background-color: #D8E3FF !important;
    }

    // &:focus {
    //     // background: linear-gradient( 180deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
    //     // background-color: #172b70;
    //     color: #fff !important;
    //     background-color: #172b70 !important;
    //     // background-color: #182B72;
    // }
}

// .ant-btn-primary {
//     // background: linear-gradient( 0deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%);
//     background-color: #fff;
//     color: #000;
//     // background-color: #182B72;
//     // &:hover{
//     //     background: linear-gradient( 90deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%);
//     //     // background-color: #182B72;
//     // }
//     &:hover{
//         border-color: #172b70;
//         background-color: #172b70;
//     }

//     &:focus{
//         // background: linear-gradient( 180deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
//         background-color: #172b70;
//         // background-color: #182B72;
//     }
// }


.ant-checkbox-checked .ant-checkbox-inner // .ant-checkbox-indeterminate .ant-checkbox-inner

    {
    background-color: linear-gradient(0deg, rgb(23, 23, 58) 0%, rgb(12, 45, 91) 51%, rgb(0, 66, 124) 100%) !important;
    // background-color: #182B72 !important;
    border-color: #182B72;
}

.ant-modal-content,
.ant-modal-header {
    border-radius: 8px !important;
    /* background: red; */
}

.bg-light {
    background: #f5f8ff !important;
}

.ant-tabs-nav {
    background-color: #F4F3FF !important;
    padding: 2% 0 0 2%;
    // background-color: red;
}

// sub_menu.scss

.lg-device-menu {
    display: block;

    @media(max-width: 769px) {
        display: none !important;
    }
}

.lg-sub-menu {
    font-family: 'Graphik';
    font-style: normal;
    display: block;
    height: 75vh;

    @media(max-height: 600px) {
        height: 80vh;
    }

    @media(max-width: 769px) {
        display: none !important;
    }
}

.lg-search-wrapper {
    display: block;

    @media(max-width: 769px) {
        display: none !important;
    }
}

.mobile-search-wrapper {
    display: none;

    @media(max-width: 769px) {
        display: block !important;
    }
}

.custom-quill-editor {
    .ql-editor {
        /* Adjust the minimum height as needed */
        min-height: 180px;
    }

    .ql-toolbar.ql-snow {
        background: #F4F3FF;
    }
}

// .mobile-sub-menu {
//     font-family: 'Graphik';
//     font-style: normal;
//     display: none;

//     .loading-container {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         height: 40vh;
//     }

//     @media(max-width: 769px) {
//         display: block;
//         padding: 0 !important;
//     }

//     .form-item {
//         p {
//             font-weight: 500;
//             font-size: 16px;
//             line-height: 18px;
//             letter-spacing: 0.01em;
//             color: #000000;
//         }
//     }

//     .convert-button {
//         position: absolute;
//         right: 8%;
//         bottom: 22%;
//         height: 22px;
//         background: #E4E7EC;
//         border-radius: 10px;
//         padding: 0 10px;
//         font-weight: 400;
//         font-size: 12px;
//         line-height: 12px;
//         letter-spacing: 0.01em;
//         color: #000000;
//     }

//     .save-change-button {
//         padding: 0 20px;
//         gap: 10px;
//         height: 38px;
//         background: #D8E3FF;
//         border-radius: 5px;
//     }
// }

.ant-tabs-nav-wrap {
    background-color: #F4F3FF !important;
    padding: 4% 0 0 0;
}