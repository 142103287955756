.workspace-lg {
    display: block;

    .lg-workspace-btn {
        margin-top: 1px;
        text-align: left;
        min-height: 40px;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1.2px solid #E6E6E6;
        min-width: 160px;
        font-size: 16px;
        padding: 0 20px;
    }

    @media(max-width: 576px) {
        display: none;
    }
}

.workspace-mobile {
    display: block;
    // display: none;

    // @media(max-width: 769px) {
    //     display: block;
    // }
}

.main-user-profile-avatar {
    height: 32px;
    width: auto;
    border-radius: 50%;
}

.mobile-workspace {
    display: none;
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #1570EF;

    @media(max-width: 769px) {
        display: block !important;

    }
}

.ant-input {
    width: 100% !important;
}

@media(max-width: 769px) {
    .ant-input {
        margin: 0px 0px 0px 0px !important;
    }
}