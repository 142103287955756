.ant-table-header .ant-table-thead tr th {
    // background: #fff;
    // background: linear-gradient( 90deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
    background: transparent !important;
    // vertical-align: auto !important;
    // color: #fff;
}

.ant-table-header .ant-table-thead {
    background: #fff;
    // background: linear-gradient( 90deg, rgb(23,23,58) 0%, rgb(12,45,91) 51%, rgb(0,66,124) 100%) !important;
    // background: transparent !important;
    // vertical-align: auto !important;
    // margin: 10px 0;
    color: #000;
}

// .ant-table-body{
//     margin-top: 20px !important;
//     margin-bottom: 2px;
//     .ant-table-row{
//         margin-top: 8px !important;
//         // margin-bottom: 8px !important;
//         // margin-top: 8px !important;

//     }
// }


.ant-table-tbody>tr>td {
    // border: none !important;
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    // margin: 5px 0 !important;
}

.rowClassName1 {
    // display: block;
    width: 100% !important;
    // margin: 10px 0 !important;
}

.rowClassName1 td:first-child {
    border-left: 1px solid #f0f0f0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.rowClassName1 td:last-child {
    border-right: 1px solid #f0f0f0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}