.image-label {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        width: auto;
        height: 100%;
        margin: auto;
        object-fit: cover;

        @media(max-width: 768px) {
            width: 15vw;
            height: auto;
            margin: 0;
        }
    }

    span {
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.business-identities-settings-gap {
    gap: 5% !important;
}