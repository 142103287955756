.lg-edition-tabs {
    position: sticky;
    top: 0;
    width: 100%;
    background: #FFFFFF;
    // padding: 1.5% 2.5%;
    padding: 0.5% 2.5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    // border: 1px solid #00f815;

    .back-path {
        // position: absolute;
        // left: 3%;
        // top: 20%;
        cursor: pointer;
        border-radius: 5px;
        background: #F4F3FF;
        padding: 0.5% 0.8%;
    }

    .save-edition-container {
        width: 100%;
        // border: 1px solid #f80074;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .save-edition {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 3rem;

            .children-btn {
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }
        }
    }
}

.sm-save-edition-container {
    width: 100%;
    // border: 1px solid #f80074;
    display: none;

    @media(max-width: 769px) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        height: 7%;
        position: absolute;
        bottom: 70px;
    }

    .save-edition {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 3rem;

        .children-btn {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
}

.sm-edition-tabs {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 5% 0;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    background: transparent;

    @media(max-width: 769px) {
        display: flex;
        background: #F4F3FF;
    }

    .tab,
    .tab-active {
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 500;
        color: #000;
        margin: 0;
        // line-height: 1rem;
        border-bottom: 2px solid transparent;
    }

    .tab-active {
        color: #0019f8;
        border-bottom: 2px solid #0019f8;
    }

    .back-path {
        position: absolute;
        left: 1%;
        top: 10%;
        cursor: pointer;
        border-radius: 5px;
        background: #F4F3FF;
        padding: 1rem;
    }
}

.master-edition {
    width: 72%;
    height: 100%;
    // border: 1px solid #00f829;
    border-radius: 5px 5px 0 0;
    overflow: auto;
    // padding: 5%;
    // padding-right: 7px;

    &::-webkit-scrollbar-thumb {
        background: #E4E7EC;
        border-radius: 10px;
        cursor: pointer;
    }

    &::-webkit-scrollbar {
        width: 10px;

        @media(max-width: 769px) {
            width: 0;
        }

        height: 5px;
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        height: 5px;
        box-shadow: inset 0 0 5px transparent;
        background-color: transparent;
        margin: 0;
        cursor: pointer;
    }

    @media(max-width: 769px) {
        width: 100%;
        // height: 80.1%;
        height: 72%;
    }

    .master-form {
        width: 70%;
        padding: 3% 3%;
        margin: 2rem auto;

        @media(max-width: 769px) {
            width: 95%;
            padding: 1rem 3%;
        }

        border-radius: 5px;
        // background: #F4F3FF;
        border: 1px solid #A4BCFD;
    }
}

.general-preview-edition {
    width: 28%;
    height: 100%;
    overflow: auto;
    // border: 1px solid #f80074;
    // background: #ABB9CC;
    background: #F4F3FF;
    border-radius: 5px 5px 0 0;

    @media(max-width: 769px) {
        width: 100%;
        // height: 80.1%;
        // height: 72%;
        height: 68%;
    }

    display: flex;
    flex-direction: column;

    .header-container {
        width: 100%;
        padding: 5% 8%;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        border-bottom: 1px solid #ABB9CC;

        .header-tab,
        .header-tab-active {
            font-size: 0.75rem;
            font-weight: 500;
            color: #000;
            cursor: pointer;
            // padding: 0.5rem 0;
            border-bottom: 2px solid transparent;
        }

        .header-tab-active {
            color: #0019f8;
            border-bottom: 2px solid #0019f8;
        }
    }

    .general-container {
        text-align: start;
        padding: 3.5% 4%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .info-body {
            border-left: 2px solid #ABB9CC;
            padding: 0.2rem 0.8rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;

            .body-content {
                font-size: 0.75rem;
                font-weight: 500;
                color: #000000;
            }
        }

        .general-form {
            padding: 0.2rem 0;

            .input-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
                width: 100%;
                margin: 0.5rem 0;

                label {
                    font-size: 0.75rem;
                    color: #667085;
                }

            }
        }

        .state-collaborators {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .state-container {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .title {
                    font-size: 0.75rem;
                    font-weight: 500;
                    color: #667085;
                }

                .state-body {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                }
            }

            .collaborator-container {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .title {
                    font-size: 0.75rem;
                    font-weight: 500;
                    color: #667085;
                }

                .collaborator-body {
                    display: flex;
                    flex-wrap: wrap;

                    img {
                        margin-right: -0.2rem;
                        border-radius: 50%;
                        height: 4vh;
                        width: auto;
                        // width: 5vw;
                    }
                }
            }

        }
    }
}

.variable-input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
    margin: 0.5rem 0;

    label {
        font-size: 0.75rem;
        color: #667085;
    }

    .variable-input {
        border-radius: 4px;
        padding: 1.6% 3%;
    }

    .image-label {
        position: absolute;
        cursor: pointer;
        border: 1px solid #ABB9CC;
        border-radius: 4px;
        padding: 0.8% 2%;
        // line-height: 0;
        top: 50%;
        right: 3%;
    }
}