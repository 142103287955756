.review-wrap {
    background: #FFFFFF;
    border: 1px solid #E1F2FF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 2.4vh 1.5vw 3.2vh 1.5vw;
    margin: auto;
    height: 24vh;
    // width: 50%;

    @media(max-width: 1400px) {
        // width: 22.5vw;
        height: 23vh;
    }

    @media(max-width: 764px) {
        width: 100% !important;
        // margin: 0 10px 0 5px !important;
    }

    // margin: 0 0px 10px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    .reviews {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        // line-height: 18px;
        // text-transform: lowercase;
        color: #8999AB;
        text-align: left;
    }

    .review-title {
        height: 11.2vh !important;

        @media(max-width: 1400px) {
            height: 8vh !important;

            @media(max-width: 764px) {
                height: 7vh !important;
            }
        }

        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-top: 0;
        font-family: 'Graphik';
        font-style: normal;
        // font-size: 0.95em;
        // border-bottom: 1px solid red;
    }

    .review-ratings {
        height: 100%;
        position: relative;

        .carousel {
            height: 21vh;

            @media(max-width: 1400px) {
                height: 20vh;
            }

            @media(max-width: 764px) {
                height: 15vh;
            }

            // border: 1px solid red;
        }

        .title {
            width: 53%;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 1vh;

            h3 {
                font-weight: 500;
                font-size: 0.9em;
                line-height: 18px;
                text-transform: capitalize;
                color: #17173A;
            }

            h4 {
                font-weight: 400;
                font-size: 0.9em;
                line-height: 19px;
                text-transform: capitalize;
                color: #8999AB;
            }
        }

        .description {
            width: 48%;
            font-family: "Graphik";
            font-style: normal;
            font-weight: normal;
            font-size: 0.9em;
            color: #8999AB;
            text-align: left;

            .review-replied {
                display: flex;
                flex-direction: column;
                gap: 1vh;
            }
        }

        .no-data {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            // padding: 20%;
            // border: 1px solid red;
        }

        // margin: 0 0 10px;
        .carousel-indicators {
            display: none;
        }

        // .carousel-item{
        // display: flex;
        // justify-content: space-between;
        // }
        .carousel-control-next {
            top: 75%;
            right: 43%;

            .carousel-control-next-icon {
                height: 22px !important;
                background-image: url('../../assets/img/arrow-right.png') !important;
            }
        }

        .carousel-control-prev {
            top: 75%;
            left: 35%;

            .carousel-control-prev-icon {
                height: 22px !important;
                background-image: url('../../assets/img/arrow-left.png');
            }
        }

        .review-item-wrap {
            margin-top: 1.8vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border: 1px solid red;
            height: 100%;
            align-items: center;
        }

        .ratings-wrap {
            font-family: 'Graphik';
            font-style: normal;
            display: flex;
            flex-direction: column;
            height: 50%;

            .rating {
                .star-rating {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                    font-size: 0.9em;
                    line-height: 18px;

                    .star-value {
                        text-transform: capitalize;
                        color: #17173A;
                        padding: 0 12px 0 0;
                    }

                }
            }

            .reviews {
                font-family: 'Graphik';
                font-style: normal;
                font-weight: 400;
                font-size: 0.9em;
                line-height: 17px;
                // text-transform: lowercase;
                color: #858585;
                text-align: left;
            }
        }

        .count-wrap {
            height: 50%;
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: left;
            gap: 1vh;

            .count {
                font-family: 'Graphik';
                font-style: normal;
                font-weight: 500;
                font-size: 0.9em;
                line-height: 18px;
                text-transform: capitalize;
                color: #17173A;
            }

            .count-title {
                font-family: 'Graphik';
                font-style: normal;
                font-weight: 400;
                // font-size: 10px;
                font-size: 0.9em;
                // line-height: 12px;
                color: #858585;
            }
        }
    }
}