%font-shared {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: normal;
    text-transform: capitalize;
}

.google-low-rated-review {
    // margin: 0 auto;
    font-family: 'Graphik';
    font-style: normal;
    height: 27vh;

    @media(max-width: 1400px) {
        // margin-top: 40px;
        height: 28.5vh;
    }

    padding: 1% 2%;
    overflow-y: auto;

    table {
        height: 100%;
        width: 100%;

        tbody {
            height: 100%;
            // border: 2px solid red;

            tr {
                td {
                    font-family: 'Grahik';
                    font-style: normal;

                    color: #5A5A5A;
                    // flex: 1;
                    text-align: left;
                    vertical-align: middle;

                    .user-info {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 1.8vw;
                        height: auto;
                        overflow: hidden;

                        @media(max-width: 769px) {
                            width: 6.8vw;
                        }
                    }

                    .user-name {
                        font-family: 'Graphik';
                        font-style: normal;
                        font-size: 0.7em;
                        font-weight: 600;

                        @media(max-width: 1400px) {
                            font-weight: 700;
                            font-size: 0.73em;
                        }
                    }

                    .service-des,
                    .rating {
                        font-family: 'Graphik';
                        font-style: normal;
                        font-size: 0.9em;

                        @media(max-width: 1400px) {
                            font-size: 0.8em;
                        }
                    }

                    .service-des {
                        font-weight: 400;
                    }

                    .rating {
                        font-weight: 500;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}