.content-wrapper {
    font-family: 'Graphik';
    font-style: normal;
    background: #FFFFFF;
    border: 1px solid #E1F2FF;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    width: 100%;
    height: 40vh;

    @media(max-width: 1400px) {
        height: 39.9vh;

        @media(max-width: 769px) {
            width: 100% !important;
            height: fit-content !important;
        }
    }

    .head-wrapper {
        display: flex;
        background: #E3F0FF;
        padding: 2% 1.8%;
        justify-content: space-between;
        border-radius: 10px 10px 0px 0px;
        align-items: center;
        // height: 34px;
        height: 17%;

        h3 {
            font-family: 'Graphik';
            font-style: normal;
            font-weight: 500;
            font-size: 1.2em;

            @media(max-width: 1400px) {
                font-size: 1em;
            }

            line-height: 13px;
            // line-height: 18px;
            text-transform: capitalize;
            color: #17173A;
            margin: 0;
        }

        span {
            font-family: 'Graphik';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 10px;
            /* identical to box height, or 157% */

            letter-spacing: 0.21875px;

            color: #B3B8BD;
            text-transform: capitalize;
        }

        img {
            height: 2vh;
            width: auto;
        }
    }
}