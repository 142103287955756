.commerce-template-edition-section {
    height: 100%;
    width: 96.9%;
    // background: #F4F3FF;
    // border: 2px solid red;
    border-radius: 5px 5px 0px 0px;
    padding: 0;
    position: relative;
    margin-top: 0.945%;

    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    @media(max-width: 769px) {
        flex-direction: column;
        width: 100%;
        margin-top: 0;
        padding-top: 0;
    }

    .content-buttons {
        // width: fit-content;
        width: 25%;
        display: flex;
        flex-direction: row;
        // justify-content: space-around;
        justify-content: flex-end;
        align-items: center;
        gap: 2%;

        @media(max-width: 769px) {
            margin-top: 0;
            width: 100%;
        }

        .template-btn {
            cursor: pointer;
            // height: 5.5vh;
            border: 1px solid #A4BCFD;
            // width: 10vw;
            // width: fit-content;
            height: 5vh;
            padding: 0 1rem;
            background: #FFFFFF;

            &:hover {
                background: #F4F3FF;
            }

            @media (max-width: 1025px) {
                width: fit-content;
                color: #000000;
            }
        }

        .template-download-btn {
            cursor: pointer;
            font-size: 0.75rem;
        }
    }
}

.commerce-section {
    // height: 90%;
    height: 100%;
    width: 96.9%;
    background: #F4F3FF;
    border-radius: 5px 5px 0px 0px;
    // min-height: 60vh;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    margin-top: 0.945%;

    @media(max-width: 769px) {
        width: 100%;
        margin-top: 0;
        padding-top: 5%;
    }

    .content-section {
        font-family: 'Graphik';
        font-style: normal;
        background: #F4F3FF;
        // background: #EEF4FF;
        // background: #F5FAFF;
        border-radius: 5px 5px 0px 0px;
        // min-height: 60vh;
        display: flex;
        flex-direction: column;
        // padding: 0.5% 0 0 0;
        // padding: 10px 0 0 0;
        // padding: 10px 0 0 0 !important;

        // padding: 1% 0;
        position: relative;
        margin-top: 0.945%;
        // margin-top: 0.75rem;

        @media(max-width: 699px) {
            margin-top: 0;
        }

        .content-search-section {
            display: flex;
            flex-direction: row;
            // justify-content: space-around;
            justify-content: center;
            align-items: center;
            gap: 2%;

            @media(max-width: 769px) {
                gap: 12%;
                flex-direction: column;
            }

            .search-wrap {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                // gap: 5%;
                position: relative;
                z-index: 1;
                // width: 500px;
                width: 50%;
                // border: 2px solid red;

                @media(max-width: 769px) {
                    width: 100%;
                }

                // @media(max-width: 1025px) {
                //     width: 90%;

                //     @media(max-width: 699px) {
                //         display: none;
                //     }
                // }

                // .content-path {
                //     cursor: pointer;
                //     width: 40%;
                //     display: flex;
                //     flex-direction: row;
                //     align-items: center;
                //     gap: 5%;

                //     .content-path-back {
                //         height: 20px;
                //         width: 50px;
                //     }

                //     .content-path-icon {
                //         font-size: 0.875rem;
                //         line-height: 0.875rem;
                //         height: 1.5vh;
                //         width: auto;
                //     }

                //     .content-path-text {
                //         font-family: 'Graphik';
                //         font-style: normal;
                //         font-weight: 400;
                //         font-size: 0.875rem;
                //         line-height: 0.875rem;

                //         @media(max-width: 1600px) {
                //             font-size: 0.75rem;
                //             line-height: 0.75rem;
                //         }

                //         letter-spacing: 0.01em;
                //         color: #000000;
                //         cursor: pointer;
                //     }
                // }

                .search-icon {
                    position: absolute;
                    left: 26.5%;
                    top: 31%;
                    height: auto;
                    width: 1vw;
                    cursor: pointer;
                }

                input {
                    width: 75%;
                    margin: 0 0 0 auto;
                    height: 5vh;
                    background: #FFFFFF;
                    border: 1.3px solid #D3E0FF;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 0.7% 0 0 4.5%;
                    outline: none;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    letter-spacing: 0.01em;

                    @media(max-width: 1600px) {
                        font-size: 0.75rem;
                        line-height: 0.75rem;

                        @media(max-width: 769px) {
                            width: 100% !important;
                        }
                    }

                    color: #98A2B3;
                }

                input::placeholder {
                    font-family: 'Graphik';
                    font-style: normal;
                    font-weight: 400;
                    color: #98A2B3;
                    font-size: 0.875rem;
                    line-height: 0.875rem;

                    @media(max-width: 1600px) {
                        font-size: 0.75rem;
                        line-height: 0.75rem;
                    }

                    letter-spacing: 0.02em;
                }
            }

            .content-buttons {
                // width: fit-content;
                width: 25%;
                display: flex;
                flex-direction: row;
                // justify-content: space-around;
                justify-content: flex-end;
                align-items: center;
                gap: 2%;

                @media(max-width: 769px) {
                    margin-top: 5%;
                    width: 100%;
                }

                .template-download-btn {
                    cursor: pointer;
                    font-size: 0.75rem;
                }
            }

            .bulk-delete-icon {
                height: auto;
                width: auto;
                cursor: pointer;

                @media(max-width: 769px) {
                    height: auto;
                }

            }

            // .secondary,
            .create-btn {
                cursor: pointer;
                // height: 5.5vh;
                // font-size: 0.875rem;
                width: 11vw;

                @media (max-width: 1025px) {
                    width: fit-content;
                    color: #000000;
                }
            }
        }

    }

    .content-wall,
    .mobile-content-wall {
        height: 80vh;
        overflow-x: scroll;
        padding-top: 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        @media(max-width: 769px) {
            height: 100%;
            padding: 2%;
            display: block;
        }

        .form-check-input[type=checkbox] {
            cursor: pointer;
        }

        .table-responsive {
            width: 68%;
            font-size: 0.875rem;
            line-height: 0.875rem;
            letter-spacing: 0.01em;
            // border-radius: 5px;
            // border: 1px solid #D3E0FF;
            padding: 0;

            @media(max-width: 1600px) {
                font-size: 0.75rem;
                line-height: 0.75rem;

                @media(max-width: 769px) {
                    // width: 580px;
                    width: 210%;
                }
            }

        }

        .sticky-top {
            z-index: 99;
        }

        .campaign-thead {
            // background: #F4F3FF;
            // z-index: 1;

            tr {
                color: #667085;

                &:first-child {
                    line-height: 2rem;
                }

                th {
                    font-weight: 500;
                    // padding-left: 2rem;

                    &:first-child {
                        border-radius: 5px 0 0 0;
                        width: 25%;
                    }

                    &:last-child {
                        border-radius: 0 5px 0 0;
                    }

                    background: #FAFAFA;
                }

                // .failed,
                // .replied {
                //     width: 15%;
                // }

                // .status {
                //     width: 10%;
                // }

            }

            @media(max-width: 769px) {
                .form-check-input[type=checkbox] {
                    width: 35% !important;
                }
            }
        }

        .template-thead {
            // background: #F4F3FF;
            // z-index: 1;

            tr {
                color: #667085;

                &:first-child {
                    // width: 5%;
                    line-height: 2rem;
                }

                th {
                    font-weight: 500;
                    // padding-left: 2rem;

                    &:first-child {
                        border-radius: 5px 0 0 0;
                    }

                    &:last-child {
                        border-radius: 0 5px 0 0;
                    }

                    background: #FAFAFA;
                }

            }

            @media(max-width: 769px) {
                .form-check-input[type=checkbox] {
                    width: 35% !important;
                }
            }
        }

        .infinity-scroll {
            height: 70vh !important;
            overflow: scroll;
            // border: 1px solid #D3E0FF;

            // &:hover {
            //     ::-webkit-scrollbar-thumb {
            //         // -webkit-transition: background-color 0.5s ease-in-out !important;
            //         // -moz-transition: background-color 0.5s ease-in-out !important;
            //         // -o-transition: background-color 0.5s ease-in-out !important;
            //         // transition: background-color 0.5s ease-in-out !important;
            //         background: #ddd !important;
            //         height: 10px !important;
            //     }
            // }

            ::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                cursor: pointer;
            }

            ::-webkit-scrollbar-track {
                height: 5px !important;
                box-shadow: inset 0 0 5px transparent;
                background-color: transparent !important;
                margin: 0 !important;
            }

            ::-webkit-scrollbar-thumb {
                background-color: transparent !important;
                border-radius: 10px !important;
            }

            // background: #FFFFFF;
        }

        .campaign-table {
            border: 1px solid #D3E0FF;
            border-radius: 5px 5px 0 0;

            tbody tr {
                border-bottom: 1px solid #D3E0FF;

                td {
                    // padding: 2% 0;
                    // text-align: center;
                    line-height: 2rem;
                }

            }

            @media(max-width: 769px) {
                .form-check-input[type=checkbox] {
                    width: 35% !important;
                }
            }

        }

        .template-table {
            border: 1px solid #D3E0FF;
            border-radius: 5px 5px 0 0;

            tbody tr {
                border-bottom: 1px solid #D3E0FF;
                vertical-align: middle;

                td {
                    // &:first-child {
                    //     width: 5%;
                    // }
                    height: 100%;

                    .user-template {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        // justify-content: center;
                        gap: 0.5rem;
                    }

                    // padding: 2% 0;
                    // text-align: center;
                    line-height: 2rem;
                }

            }

            @media(max-width: 769px) {
                .form-check-input[type=checkbox] {
                    width: 35% !important;
                }
            }

        }

        .content-table {
            // background: #FFFFFF;
            // height: 500px;

            tr {
                border-bottom: 1px solid #D3E0FF;

                td {
                    padding: 2% 0;
                    text-align: center;

                    .table-image-container {
                        height: 2.8vh;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 5%;

                        img,
                        span {
                            cursor: pointer;
                            width: auto;
                            // margin: -4% 0;
                        }


                        // @media(max-width: 769px) {
                        //     align-items: flex-start;
                        //     flex-direction: column;

                        //     span {
                        //         margin-left: 0;
                        //         margin-top: 1%;
                        //     }
                        // }
                    }


                    .table-switch {
                        cursor: pointer;
                        width: 30%;
                        height: auto;
                    }
                }

                td:first-child {
                    padding: 2%;
                    text-align: start;
                }
            }

            @media(max-width: 769px) {
                .form-check-input[type=checkbox] {
                    width: 35% !important;
                }
            }

        }


    }

}

.loader-container {
    margin: 2.5% auto;

    .loader-title {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem !important;
        // line-height: 1.5rem;
        letter-spacing: 0.01em;
        color: #000000;

        @media(max-width: 769px) {
            font-size: 1.25rem !important;
            line-height: 1.25rem;
        }
    }

    .loader-body {
        background: #D8E3FF;
        padding: 6% 2% 4% 2%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        margin: 5% auto 6% auto;
        border-left: 5px solid #1570EF;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.content-dropdown {
    background: #FFFFFF;
    box-shadow: 0.6px 0.6px 12px 4px rgba(23, 23, 58, 0.1);
    border-radius: 5px;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4.5%;
    width: 160px;

    p,
    a,
    label {
        padding: 4%;
        margin: 0;
        width: 100%;
        text-decoration: none;
        color: #101828;
        font-size: 0.75rem;
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: #2c3039;
            background: #D8E3FF;
        }
    }
}