.lg-device-menu {
    display: block;

    @media(max-width: 769px) {
        display: none !important;
    }
}

.feature-input-container {
    height: 100% !important;
}

.border-wrapper {
    .special-group {
        margin: 0 0 5px 0;

        input,
        select,
        .image-label {
            height: 59px;
            box-shadow: 2px 2px 12px 0px #0000001a;
            outline: none;
            padding-right: 35%;
        }

        position: relative;

        .button {
            position: absolute;
            top: 42px;
            right: 25px;
            width: 152px;
            height: 35px;
            // background: #edeff3;
            border: 1px solid #e5e5e5;
            outline: none;
            cursor: pointer;

            &:hover {
                background: #b6bac0;
            }
        }

        .switch {
            // width: 20px !important;
            position: absolute;
            top: 50px;
            // right: 25px;
            right: 78px;
            // &:active{
            // }
        }

        .switch-active {
            background: #2582f5;
        }

        @media (max-width: 769px) {
            .button {
                width: 100px;
                font-size: 12px;
            }

            input::placeholder,
            select::placeholder {
                font-size: 12px;
            }
        }
    }

    &:nth-child(1) {
        .border-custom {

            input,
            select {
                border-left: 3.5px solid #65b9fc;
            }
        }
    }

    &:nth-child(2) {
        .border-custom {

            input,
            .image-label {
                border-left: 3.5px solid #67b638;
            }
        }
    }

    &:nth-child(3) {
        .border-custom {
            input {
                border-left: 3.5px solid #ffbe74;
            }
        }
    }

    &:nth-child(4) {
        .border-custom {
            input {
                border-left: 3.5px solid #ffe065;
            }
        }
    }

    &:nth-child(5) {
        .border-custom {
            input {
                border-left: 3.5px solid #2582f5;
            }
        }
    }

    &:nth-child(6) {
        .border-custom {
            input {
                border-left: 3.5px solid #bcea6c;
            }
        }
    }
}

.cancel-btn {
    // color: #000;
    background: #e1e7ed;
    border-radius: 5px;
    color: #000;

    &:hover {
        background: #172b70;
        color: #fff;
    }

    &:focus {
        background: #172b70;
    }
}

.ant-switch-checked {
    background: #2582f5 !important;
    // background: red !important;
}