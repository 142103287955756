body {
  font-family: 'Graphik';
  /* font-family: 'Graphik'; */

}

::-webkit-scrollbar {
  width: 3px;
  padding: 0;
  margin: 0;
  min-width: 0;
  height: 5px;
  border: none;
  outline: none;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  height: 0;
}

.App {
  text-align: center;
}

.scroll-content {
  /* padding: 17px 0 17px 17px; */
  /* width: 300px;
  height: 200px; */
  overflow-y: scroll;
  /* mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px); */
  mask-size: 100% 20000px;
  mask-position: left bottom;
  /* -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px); */
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s;


  &::-webkit-scrollbar-thumb {
    height: 100px !important;
    transition: all 0.5s ease-in;
  }


  &:hover {
    &::-webkit-scrollbar-thumb {
      // -webkit-transition: background-color 0.5s ease-in-out !important;
      // -moz-transition: background-color 0.5s ease-in-out !important;
      // -o-transition: background-color 0.5s ease-in-out !important;
      // transition: background-color 0.5s ease-in-out !important;
      border-radius: 5px;
      opacity: 0.6;
      background: var(--icon-general, #ABB9CC);
    }

  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    cursor: pointer;
    // height: 5px;
    box-shadow: inset 0 0 5px #fff;
    background-color: #fff;
    margin: 0;
  }
}